// Import Third-party Packages
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import StyledSelectMenu from '../../common/StyledSelectMenu'

// Import Actions
import { layerConfigChange } from 'kepler.gl/actions'

class LayerBasicColumns extends React.PureComponent {
    // Handle On Layer Field Change
    handleOnLayerFieldChange = event => {
        const { layer, dataFields } = this.props
        const field = dataFields.find(field => field.name === event.target.value)
        if(!field) {
            return
        }
        
        const newConfig = {
            columns: {
                ...layer.config.columns,
                [ event.target.name ]: {
                    fieldIdx: field.tableFieldIndex-1,
                    value: event.target.value
                }
            }
        }

        this.props.dispatch( layerConfigChange(layer, newConfig) )
    }

    ///////////////
    // Utilities //
    ///////////////
    // Get Columns List
    _getDataColumns = () => {
        return this.props.dataFields.map((field, index) => ({
            id: index,
            value: field.name,
            label: field.name,
            fieldType: field.type,
            tableFieldIndex: field.tableFieldIndex
        }))
    }

    render() {
        const { layer } = this.props

        return (
            <Box width='100%' padding='8px'>
                <Typography variant='caption' style={{ color: 'rgba(255, 255, 255, 0.6)' }}>{ 'Columns' }</Typography>

                {
                    layer.config.columns &&
                    Object.keys(layer.config.columns).map((column, index) =>
                        <StyledSelectMenu
                            key={ Math.random(100) + index }
                            label={ column }
                            name={ column }
                            value={ layer.config.columns[column].value ? layer.config.columns[column].value : '' }
                            menuItems={ this._getDataColumns() }
                            onChange={ this.handleOnLayerFieldChange }
                            required={ layer.config.columns[column].optional ? false : true }
                        />
                    )
                }
            </Box>
        )
    }
}

// Prop Types
LayerBasicColumns.propTypes = {
    layer: PropTypes.object.isRequired,
    dataFields: PropTypes.array
}

LayerBasicColumns.defaultProps = {
    dataFields: []
}

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(null, mapDispatchToProps)(LayerBasicColumns)