// Import Third-party Packages
import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'

class Footer extends React.PureComponent {
    state = {
        background: 'rgba(56, 64, 78, 0.8)',
        borderTop: '1px solid rgba(255, 255, 255, 0.5)',
        color: 'rgba(255, 255, 255, 0.5)'
    }

    componentDidMount() {
        const { theme } = this.props
        this._setTheme(theme)
    }

    componentDidUpdate(prevProps) {
        const { theme } = this.props

        // If Theme changes in props
        if(prevProps.theme !== theme) {
            this._setTheme(theme)
        }
    }

    // Set Theme based on `theme` prop
    _setTheme = theme => {
        let background = ''
        let borderTop = ''
        let color = ''

        switch(theme) {
            case 'light':
                background = 'rgba(255, 255, 255, 0.8)'
                borderTop = '1px solid rgba(56, 64, 78, 0.5)'
                color = 'rgba(56, 64, 78, 0.8)'
                break
            case 'dark':
                background = 'rgba(56, 64, 78, 0.8)'
                borderTop = '1px solid rgba(255, 255, 255, 0.5)'
                color = 'rgba(255, 255, 255, 0.5)'
                break
            default:
                background = 'rgba(56, 64, 78, 0.8)'
                borderTop = '1px solid rgba(255, 255, 255, 0.5)'
                color = 'rgba(255, 255, 255, 0.5)'
        }

        this.setState({ background, borderTop, color })
    }

    render() {
        const { background, color, borderTop } = this.state

        return (
            <Container disableGutters={ true } style={{ ...footerContainerStyles, background, borderTop }} >
                <Container disableGutters={ true } style={ footerStyles }>
                    <Typography variant='caption' style={{ color }}>{ 'Powered By |' }</Typography>
                    <Link href='' style={ linkStyles }>{ 'Barikoi Technologies Limited' }</Link>
                </Container>
            </Container>
        )
    }
}

// JSS Styles
const footerContainerStyles = {
    margin: '0px',
    marginTop: 'auto',
    width: '100%',
    padding: '8px',
    maxHeight: '48px'
}

const footerStyles = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
}

const linkStyles = {
    margin: '0px',
    padding: '0px',
    marginLeft: '4px',
    paddingBottom: '2px',
    color: 'rgba(45, 219, 172, 0.9)',
    fontSize: '14px'
}

Footer.propTypes = {
    theme: PropTypes.oneOf([ 'light', 'dark' ])
}

Footer.defaultProps = {
    theme: 'dark'
}

export default Footer