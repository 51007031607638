// Import Third-party Packages
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Compenents
import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import LayerBasicPanel from './LayerBasicPanel'
import LayerFillColorPanel from './LayerFillColorPanel'
import LayerRadiusPanel from './LayerRadiusPanel'
import LayerWeightPanel from './LayerWeightPanel'
import LayerTextLabelPanel from './LayerTextLabelPanel'

// Import Actions
import { removeLayer, layerConfigChange } from 'kepler.gl/actions'

class LayerAccordion extends React.PureComponent {
    state = {
        expanded: true
    }

    // Handle Toggle Expande/Collapse
    handleToggleExpandCollapse = () => {
        const { expanded } = this.state
        this.setState({ expanded: !expanded })
    }

    // Handle Layer Delete
    handleLayerDelete = event => {
        event.stopPropagation()

        // Remove Layer By Index
        this.props.dispatch( removeLayer(this.props.layerIndex) )
    }

    // Handle Layer Visibility
    handleLayerVisibility = event => {
        event.stopPropagation()

        // Set Layer Visibility
        const { layer } = this.props
        this.props.dispatch( layerConfigChange(layer, { isVisible: !layer.config.isVisible }) )
    }

    render() {
        const { layer, layerType, dataFields } = this.props
        const { expanded } = this.state

        return (
            <Box width='100%' marginBottom='8px'>
                <StyledAccordion
                    expanded={ expanded }
                    onChange={ this.handleToggleExpandCollapse }
                >
                    <StyledAccordionSummary expandIcon={ <ExpandMoreIcon style={{ color: '#fff' }} /> }>
                        <Box width='100%' flexDirection='column'>
                            <Typography variant='body2'>{ layer.config.label }</Typography>
                            <Typography
                                variant='caption'
                                style={{ color: 'rgba(255, 255, 255, 0.5)' }}
                            >
                                { layerType }
                            </Typography>
                        </Box>
                        <Box display='flex' alignItems='center'>
                            <IconButton
                                size='small'
                                onFocus={ event => event.stopPropagation() }
                                onClick={ this.handleLayerDelete }
                            >
                                <DeleteIcon fontSize='small' style={{ color: '#fff', opacity: '0.6' }} />
                            </IconButton>
                            <IconButton
                                size='small'
                                onFocus={ event => event.stopPropagation() }
                                onClick={ this.handleLayerVisibility }
                            >
                                { layer.config.isVisible ?
                                    ( <VisibilityIcon fontSize='small' style={{ color: '#fff', opacity: '0.6' }} /> )
                                    :
                                    ( <VisibilityOffIcon fontSize='small' style={{ color: '#fff', opacity: '0.6' }} /> )
                                }
                            </IconButton>
                        </Box>
                    </StyledAccordionSummary>
                    <StyledAccordionDetails>
                        <Box width='100%' flexDirection='column'>
                            <LayerBasicPanel layer={ layer } layerType={ layerType } dataFields={ dataFields } />

                            { (layerType === 'point' || layerType === 'hexagon') &&
                                <LayerFillColorPanel layer={ layer } dataFields={ dataFields } />
                            }

                            <LayerRadiusPanel layer={ layer } layerType={ layerType } dataFields={ dataFields } />

                            { layerType === 'heatmap' &&
                                <LayerWeightPanel layer={ layer } dataFields={ dataFields } />
                            }

                            { layerType === 'point' &&
                                <LayerTextLabelPanel layer={ layer } dataFields={ dataFields } />
                            }
                        </Box>
                    </StyledAccordionDetails>
                </StyledAccordion>
            </Box>
        )
    }
}

// JSS Styled Components (whitStyles)
const StyledAccordion = withStyles({
    root: {
        background: 'transparent',
        boxShadow: 'none',
        border: '1px solid rgba(255, 255, 255, 0.2)'
    }
})(Accordion)

const StyledAccordionSummary = withStyles({
    root: {
        margin: '0px',
        background: 'rgba(56, 64, 78, 0.8)',
        color: '#fff',
        fontSize: '14px',
        maxHeight: '32px',
        overflow: 'hidden'
    }
})(AccordionSummary)

const StyledAccordionDetails = withStyles({
    root: {
        color: 'white',
        fontSize: '12px'
    }
})(AccordionDetails)

// Prop Types
LayerAccordion.propTypes = {
    layer: PropTypes.object.isRequired,
    layerIndex: PropTypes.number.isRequired,
    layerType: PropTypes.string,
    dataFields: PropTypes.array
}

LayerAccordion.defaultProps = {
    layerType: '',
    dataFields: []
}

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(null, mapDispatchToProps)(LayerAccordion)