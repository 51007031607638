// Import Third-party Packages
import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import StorageIcon from '@material-ui/icons/Storage'
import LayersIcon from '@material-ui/icons/Layers'
import FilterListIcon from '@material-ui/icons/FilterList'

class TabsMenu extends React.PureComponent {
    render() {
        const { tabIndex, onChange } = this.props

        return (
            <AppBar position='sticky' style={ appBarStyles }>
                <Tabs
                    variant='fullWidth'
                    TabIndicatorProps={{ style: { background: '#fff' } }}
                    value={ tabIndex }
                    onChange={ onChange }
                    aria-label='side-panel-tabs-menu'
                >
                    <Tab
                        icon={ <StorageIcon fontSize='small' /> }
                        aria-label='datasets'
                        id={ `tab-${ 0 }` }
                        aria-controls={ `tabpanel-${ 0 }` }
                        style={ tabStyles }
                    />

                    <Tab
                        icon={ <LayersIcon fontSize='small' /> }
                        aria-label='layers'
                        id={ `tab-${ 1 }` }
                        aria-controls={ `tabpanel-${ 1 }` }
                        style={ tabStyles }
                    />

                    <Tab
                        icon={ <FilterListIcon fontSize='small' /> }
                        aria-label='filters'
                        id={ `tab-${ 2 }` }
                        aria-controls={ `tabpanel-${ 2 }` }
                        style={ tabStyles }
                    />
                </Tabs>
            </AppBar>
        )
    }
}

// JSS Styles
const appBarStyles = {
    background: 'rgba(56, 64, 78, 0.8)'
}

const tabStyles = {
    width: '100%',
    minWidth: '12px',
}

// Prop Types
TabsMenu.propTypes = {
    tabIndex: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired
}

export default TabsMenu