// Import Third-party Packages
import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import LayerSelectMenu from './LayerSelectMenu'
import LayerBasicColumns from './LayerBasicColumns'

class LayerBasicPanel extends React.PureComponent {
    state = {
        expanded: true
    }

    // Handle Toggle Expande/Collapse
    handleToggleExpandCollapse = () => {
        const { expanded } = this.state
        this.setState({ expanded: !expanded })
    }

    render() {
        const { layer, layerType, dataFields } = this.props
        const { expanded } = this.state

        return (
            <Box width='100%' marginTop='8px' marginBottom='8px'>
                <StyledAccordion
                    expanded={ expanded }
                    onChange={ this.handleToggleExpandCollapse }
                >
                    <StyledAccordionSummary
                        expandIcon={ <ExpandMoreIcon fontSize='small' style={{ color: '#fff' }} /> }
                    >
                        <Typography
                            variant='caption'
                            style={{ color: 'rgba(255, 255, 255, 0.5)' }}
                        >
                            { 'Basic' }
                        </Typography>
                    </StyledAccordionSummary>

                    <StyledAccordionDetails>
                        <Box width='100%' display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                            <LayerSelectMenu layer={ layer } layerType={ layerType } />
                            <LayerBasicColumns layer={ layer } dataFields={ dataFields } />
                        </Box>
                    </StyledAccordionDetails>
                </StyledAccordion>
            </Box>
        )
    }
}

// JSS Styled Components (whitStyles)
const StyledAccordion = withStyles({
    root: {
        background: 'transparent',
        boxShadow: 'none'
    }
})(Accordion)

const StyledAccordionSummary = withStyles({
    root: {
        margin: '0px',
        background: 'rgba(56, 64, 78, 0.8)',
        color: '#fff',
        fontSize: '14px',
        minHeight: '32px',
        overflow: 'hidden'
    }
})(AccordionSummary)

const StyledAccordionDetails = withStyles({
    root: {
        color: 'white',
        fontSize: '12px'
    }
})(AccordionDetails)

// Prop Types
LayerBasicPanel.propTypes = {
    layer: PropTypes.object.isRequired,
    layerType: PropTypes.string,
    dataFields: PropTypes.array
}

export default LayerBasicPanel