// Import Action Types
import * as ActionTypes from '../actions/actionTypes'

const initialState = {
    isFillColorPickerOpen: false
}

const layerReducer = (state=initialState, action) => {
    switch(action.type) {
        case ActionTypes.SET_IS_FILL_COLOR_PICKER_OPEN:
            return {
                ...state,
                isFillColorPickerOpen: action.payload
            }

        default:
            return state
    }
}

export default layerReducer