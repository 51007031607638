// Import Third-party Packages
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import { ChromePicker } from 'react-color'
import Button from '@material-ui/core/Button'
import StyledSelectMenu from '../../common/StyledSelectMenu'

// Import Actions
import { layerConfigChange, layerVisConfigChange, layerVisualChannelConfigChange } from 'kepler.gl/actions'
import { setLayerFillColorPickerVisibility } from '../../../store/actions/layerActions'

class LayerFillColorPanel extends React.PureComponent {
    state = {
        expanded: true
    }

    componentDidMount() {
        if(!this.state.isFillColorPickerOpen) {
            // Attach Event Handler
            document.addEventListener('click', this.handleFillColorPickerOnBlur, false)
        }
    }

    componentWillUnmount() {
        // Remove Event Handler
        document.removeEventListener('click', this.handleFillColorPickerOnBlur, false)
    }

    // Handle Toggle Expande/Collapse
    handleToggleExpandCollapse = () => {
        this.setState({ expanded: !this.state.expanded })
    }

    // Handle Toggle Fill Color Picker
    handleToggleFillColorPicker = () => {
        this.props.dispatch( setLayerFillColorPickerVisibility(!this.props.isFillColorPickerOpen) )
    }

    // Handle Fill Color Picker On Blur/Clicked Outside
    handleFillColorPickerOnBlur = event => {
        // Ignore Clicks On The Component Itself
        if(this.fillColorPickerBoxRef && this.fillColorPickerBoxRef.contains(event.target)) {
            return
        }

        if(this.props.isFillColorPickerOpen) {
            this.props.dispatch( setLayerFillColorPickerVisibility(false) )
        }
    }

    // Handle Fill Color On Change
    handleFillColorOnChangeComplete = color => {
        const { layer } = this.props
        const rgbColor = [ color.rgb.r, color.rgb.g, color.rgb.b ]
        const opacity = color.rgb.a

        // Set Layer Fill Color and Opacity
        this.props.dispatch( layerConfigChange(layer, { color: rgbColor }) )
        this.props.dispatch( layerVisConfigChange(layer, { opacity }) )
    }

    // Handle On Color Field By Change
    handleColorFieldByOnChange = event => {
        const { layer } = this.props
        const dataColumns = this._getDataColumns()
        const selectedField = dataColumns.find(column => column.value === event.target.value)

        if(selectedField) {
            const newConfig = {
                colorField: {
                    analyzerType: selectedField.analyzerType,
                    format: selectedField.format,
                    id: selectedField.id,
                    name: selectedField.name,
                    tableFieldIndex: selectedField.tableFieldIndex,
                    type: selectedField.type
                }
            }

            // Dispatch Color Field By
            this.props.dispatch( layerVisualChannelConfigChange(layer, newConfig, 'color') )
        }
    }

    ///////////////
    // Utilities //
    ///////////////
    // Set Fill Color Picker Box Ref
    _setFillColorPickerBoxRef = node => {
        this.fillColorPickerBoxRef = node
    }

    // Get Columns List
    _getDataColumns = () => {
        return this.props.dataFields.map(field => ({
            ...field,
            value: field.name,
            label: field.name
        }))
    }

    render() {
        const { layer, isFillColorPickerOpen } = this.props
        const { expanded } = this.state

        return (
            <Box width='100%' marginTop='8px' marginBottom='8px'>
                <StyledAccordion
                    expanded={ expanded }
                    onChange={ this.handleToggleExpandCollapse }
                >
                    <StyledAccordionSummary
                        expandIcon={ <ExpandMoreIcon fontSize='small' style={{ color: '#fff' }} /> }
                    >
                        <Typography
                            variant='caption'
                            style={{ color: 'rgba(255, 255, 255, 0.5)' }}
                        >
                            { 'Fill Color' }
                        </Typography>
                    </StyledAccordionSummary>

                    <StyledAccordionDetails>
                        <Box
                            width='100%'
                            display='flex'
                            flexDirection='column'
                            justifyContent='center'
                            alignItems='center'
                            ref={ this._setFillColorPickerBoxRef }
                        >
                            <Button
                                fullWidth={ true }
                                style={{ padding: '8px', background: 'rgba(56, 64, 78, 0.8)' }}
                                onClick={ this.handleToggleFillColorPicker }
                            >
                                <Box
                                    width='50%'
                                    height='20px'
                                    bgcolor={ 'rgba(' + layer.config.color.join(',') + ',' + layer.config.visConfig.opacity + ')' }
                                />
                            </Button>

                            { isFillColorPickerOpen &&
                                <Box mt='8px'>
                                    <ChromePicker
                                        disableAlpha={ true }
                                        color={ 'rgba(' + layer.config.color.join(',') + ',' + layer.config.visConfig.opacity + ')' }
                                        onChangeComplete={ this.handleFillColorOnChangeComplete }
                                    />
                                </Box>
                            }

                            <StyledSelectMenu
                                label='Color By'
                                value={ layer.config.colorField ? layer.config.colorField.name : '' }
                                menuItems={ this._getDataColumns() }
                                onChange={ this.handleColorFieldByOnChange }
                            />
                        </Box>
                    </StyledAccordionDetails>
                </StyledAccordion>
            </Box>
        )
    }
}

// JSS Styled Components (whitStyles)
const StyledAccordion = withStyles({
    root: {
        background: 'transparent',
        boxShadow: 'none'
    }
})(Accordion)

const StyledAccordionSummary = withStyles({
    root: {
        margin: '0px',
        background: 'rgba(56, 64, 78, 0.8)',
        color: '#fff',
        fontSize: '14px',
        minHeight: '32px',
        overflow: 'hidden'
    }
})(AccordionSummary)

const StyledAccordionDetails = withStyles({
    root: {
        color: 'white',
        fontSize: '12px'
    }
})(AccordionDetails)

// Prop Types
LayerFillColorPanel.propTypes = {
    layer: PropTypes.object.isRequired,
    isFillColorPickerOpen: PropTypes.bool,
    dataFields: PropTypes.array.isRequired
}

const mapStateToProps = state => ({ isFillColorPickerOpen: state.app.layerState.isFillColorPickerOpen })
const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(LayerFillColorPanel)