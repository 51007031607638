// Import Third-party Packages
import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'

// Import CSS & Fonts
import 'fontsource-roboto'

// Import Components
import { withStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import CircularProgress from '@material-ui/core/CircularProgress'
import Login from './pages/Login'
import CatenaApp from './pages/CatenaApp'
// import KludioApp from './pages/KludioApp'

// Import CSS Styles
import './App.css'

// Import Actions & Methods
import { tokenValidation } from './store/actions/authActions'

class App extends React.PureComponent {
  state = {
    isAuthenticated: false,
    isLoading: true
  }

  componentDidMount() {
    // Check If login token still valid
    const token = localStorage.getItem('token')

    if(token) {
      this.setState({ isLoading: true })

      // Validate Token
      tokenValidation(token)
        .then(isAuthenticated => {
          this.setState({ isAuthenticated, isLoading: false })
        })
        .catch(err => {
          console.error(err)
          this.setState({ isAuthenticated: false, isLoading: false })
        })

    } else {
      this.setState({ isAuthenticated: false, isLoading: false })
    }
  }

  render() {
    const { isAuthenticated, isLoading } = this.state
    const token = localStorage.getItem('token')

    return (
      <Container disableGutters={ true } maxWidth={ false } style={ appContainerStyles }>
        <BrowserRouter>
          <Switch>
            <Route exact path='/'>
              {
                token && isAuthenticated && !isLoading ?
                <CatenaApp /> :
                isLoading ?
                <StyledCircularProgress /> :
                <Redirect to='/login' />
              }
            </Route>

            <Route exact path='/login'>
              {
                token && isAuthenticated && !isLoading ?
                <Redirect to='/' /> :
                isLoading ?
                <StyledCircularProgress /> :
                <Login />
              }
            </Route>

            {/* <Route exact path='/kludio'>
              <CatenaApp />
            </Route> */}
          </Switch>
        </BrowserRouter>
      </Container>
    )
  }
}

// Styled Components
const StyledCircularProgress = withStyles({
  colorPrimary: {
    color: 'rgb(45, 219, 172, 1)'
  }
})(CircularProgress)

// JSS Styles
const appContainerStyles = {
  margin: '0px',
  padding: '0px',
  width: '100vw',
  height: '100vh',
  maxWidth: '100vw',
  maxHeight: '100vh',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
}

export default App