// Data Action Types
export const DATA_REQUEST = 'DATA_REQUEST'
export const DATA_LOAD_SUCCESS = 'DATA_LOAD_SUCCESS'
export const DATA_LOAD_ERROR = 'DATA_LOAD_ERROR'
export const RESET_DATA_ERROR = 'RESET_DATA_ERROR'
export const ADD_DATA_SOURCE = 'ADD_DATA_SOURCE'
export const UPDATE_DATA_SOURCE = 'UPDATE_DATA_SOURCE'
export const REMOVE_DATA_SOURCE = 'REMOVE_DATA_SOURCE'
export const SET_IS_RUPANTOR_RUNNING = 'SET_IS_RUPANTOR_RUNNING'
export const SET_RUPANTOR_TOTAL_DATA_LENGTH = 'SET_RUPANTOR_TOTAL_DATA_LENGTH'
export const SET_RUPANTOR_PROCESSED_LENGTH = 'SET_RUPANTOR_PROCESSED_LENGTH'
export const SET_IS_API_FETCHING = 'SET_IS_API_FETCHING'
export const SET_API_TOTAL_CONTENT_LENGTH = 'SET_API_TOTAL_CONTENT_LENGTH'
export const SET_API_DATA_LOADED_LENGTH = 'SET_API_DATA_LOADED_LENGTH'

// Layer Action Types
export const SET_IS_FILL_COLOR_PICKER_OPEN = 'SET_IS_FILL_COLOR_PICKER_OPEN'

// Filter Action Types
export const SET_FILTER_VALUE_RANGE = 'SET_FILTER_VALUE_RANGE'
export const REMOVE_FILTER_VALUE_RANGE = 'REMOVE_FILTER_VALUE_RANGE'

// Reverse Geo Data Fetch Action Types
export const SET_IS_REVERSE_GEO_DATA_ON = 'SET_IS_REVERSE_GEO_DATA_ON'

// Auth Action Types
export const SET_AUTH_ERROR = 'SET_AUTH_ERROR'