// Import Third-party Packages
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import StyledSelectMenu from '../../common/StyledSelectMenu'

// Import Actions
import { layerTextLabelChange } from 'kepler.gl/actions'

class LayerTextLabelPanel extends React.PureComponent {
    state = {
        expanded: true
    }

    // Handle Toggle Expande/Collapse
    handleToggleExpandCollapse = () => {
        this.setState({ expanded: !this.state.expanded })
    }

    // Handle Text Label Field Change
    handleTextLabelFieldOnChange = event => {
        const { layer } = this.props
        const dataColumns = this._getDataColumns()
        const selectedField = dataColumns.find(column => column.value === event.target.value)

        // Dispatch Text Field By
        const textField = {
            analyzerType: selectedField.analyzerType,
            format: selectedField.format,
            id: selectedField.id,
            name: selectedField.name,
            tableFieldIndex: selectedField.tableFieldIndex,
            type: selectedField.type
        }

        // Dispatch Text Label Change
        this.props.dispatch( layerTextLabelChange(layer, 0, 'field', textField) )
    }

    ///////////////
    // Utilities //
    ///////////////

    // Get Columns List
    _getDataColumns = () => {
        return this.props.dataFields.map(field => ({
            ...field,
            value: field.name,
            label: field.name
        }))
    }

    render() {
        const { layer } = this.props
        const { expanded } = this.state

        return (
            <Box width='100%' marginTop='8px' marginBottom='8px'>
                <StyledAccordion
                    expanded={ expanded }
                    onChange={ this.handleToggleExpandCollapse }
                >
                    <StyledAccordionSummary
                        expandIcon={ <ExpandMoreIcon fontSize='small' style={{ color: '#fff' }} /> }
                    >
                        <Typography
                            variant='caption'
                            style={{ color: 'rgba(255, 255, 255, 0.5)' }}
                        >
                            { 'Text Label' }
                        </Typography>
                    </StyledAccordionSummary>

                    <StyledAccordionDetails>
                        <Box
                            width='100%'
                            display='flex'
                            flexDirection='column'
                            justifyContent='center'
                            alignItems='center'
                        >
                            <StyledSelectMenu
                                label='Label By'
                                value={ layer.config.textLabel[0].field ? layer.config.textLabel[0].field.name : '' }
                                menuItems={ this._getDataColumns() }
                                onChange={ this.handleTextLabelFieldOnChange }
                            />
                        </Box>
                    </StyledAccordionDetails>
                </StyledAccordion>
            </Box>
        )
    }
}

// JSS Styled Components (whitStyles)
const StyledAccordion = withStyles({
    root: {
        background: 'transparent',
        boxShadow: 'none'
    }
})(Accordion)

const StyledAccordionSummary = withStyles({
    root: {
        margin: '0px',
        background: 'rgba(56, 64, 78, 0.8)',
        color: '#fff',
        fontSize: '14px',
        minHeight: '32px',
        overflow: 'hidden'
    }
})(AccordionSummary)

const StyledAccordionDetails = withStyles({
    root: {
        color: 'white',
        fontSize: '12px'
    }
})(AccordionDetails)

// Prop Types
LayerTextLabelPanel.propTypes = {
    layer: PropTypes.object.isRequired,
    dataFields: PropTypes.array.isRequired
}

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(null, mapDispatchToProps)(LayerTextLabelPanel)