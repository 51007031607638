import React from 'react'
import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'

class StyledTable extends React.PureComponent {
    state = {
        isHovered: false,
        row: 0
    }

    handleMouseHover = (event, index) => {
        if (index === null || index === undefined) {
            return
        }
        
        this.setState({
            isHovered: true,
            row: index
        })
    }

    handleMouseLeave = (event, index) => {
        if (!index) {
            return
        }

        this.setState({
            isHovered: false,
            row: index
        })
    }

    // handleDelete = () => {
    //     this.props.onRowDelete
    // }

    render() {
        const { headers, rows, idList, onRowDelete } = this.props

        return (
            <Box style={{ width: '100%' }}>
                <TableContainer component={Paper} style={{ width: '100%', maxHeight: '240px' }}>
                    <Table size='small' stickyHeader={true} style={{ width: '100%', tableLayout: 'auto' }}>
                        <TableHead >
                            <TableRow >
                                {
                                    headers.map((header, index) =>
                                        <StyledHeaderTableCell key={ index } align='left' >{header}</StyledHeaderTableCell>
                                    )
                                }
                                <StyledHeaderTableCell key={ headers.length } align='left'></StyledHeaderTableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            { rows.map((row, index) => (
                                <TableRow key={ index } id={ index } hover={ true }
                                    onMouseEnter={ event => this.handleMouseHover(event, index) }
                                    onMouseLeave={ event => this.handleMouseLeave(event, index) }
                                >

                                    {
                                        row.map((cell, index) =>
                                            <StyledRowTableCell key={ index } align='left'>{cell}</StyledRowTableCell>
                                        )
                                    }

                                    <TableCell key={ row.length } padding='none' align='left'>{this.state.isHovered && this.state.row === index &&
                                        <Box>
                                            <IconButton size='small' onClick={ event => onRowDelete(event, idList[index]) }>
                                                <DeleteIcon style={{ color: 'grey', fontSize: 'small' }} />
                                            </IconButton>
                                        </Box>
                                    }</TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        )
    }
}

// JSS Styles
const StyledHeaderTableCell = withStyles({
    root: {
        fontSize: '12px',
        fontWeight: '600'
    }
})(TableCell)

const StyledRowTableCell = withStyles({
    root: {
        fontSize: '12px'
    }
})(TableCell)

// Prop Types
StyledTable.propTypes = {
    idList: PropTypes.array,
    headres: PropTypes.array,
    rows: PropTypes.array,
    onRowDelete: PropTypes.func.isRequired
}

StyledTable.defaultProps = {
    idList: [],
    headers: [],
    rows: []
}

export default StyledTable