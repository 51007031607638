import * as ActionTypes from '../actions/actionTypes'

const initialState = {
    isReverseGeoDataOn: true
}

const reverseGeoReducer = (state=initialState, action) => {
    switch(action.type) {
        case ActionTypes.SET_IS_REVERSE_GEO_DATA_ON:
            return {
                ...state,
                isReverseGeoDataOn: action.payload.isReverseGeoDataOn
            }
        
        default:
            return state
    }
}

export default reverseGeoReducer