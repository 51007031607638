import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import Box from '@material-ui/core/Box'
import { Pannellum } from 'pannellum-react'
import DataInfo from './DataInfo'

class SelectedDataInfo extends React.PureComponent {
    _getDataFields = () => {
        const { clicked } = this.props
        if(!clicked) {
            return []
        }

        const dataId = this.props.layers.find(layer => layer.id === clicked.layer.id).config.dataId
        const dataset = this.props.datasets[dataId]
        const fields = dataset.fields.map(field => field.name)
        
        return fields
    }

    _getImageLink = () => {
        const { clicked } = this.props
        if(!clicked) {
            return ''
        }

        return clicked.object.data ? clicked.object.data[ this._getDataFields().indexOf('image') ] : ''
    }

    _getDataRows = () => {
        let latIndex = -1
        let lngIndex = -1
        let imageIndex = -1
        const fields = this._getDataFields().filter((field, index) => {
            let condition = true
            field = field.toLowerCase()
            if(field.startsWith('lat') || field.endsWith('_lat')) {
                condition = false
                latIndex = index

            } else if(field.startsWith('long') || field.startsWith('lng') || field.endsWith('_long') || field.endsWith('_lng')) {
                condition = false
                lngIndex = index

            } else if(field.includes('image') || field.includes('img')) {
                condition = false
                imageIndex = index
            }

            return condition
        })
        const data = this.props.clicked && this.props.clicked.object.data ?
            this.props.clicked.object.data.filter((item, index) => index !== latIndex && index !== lngIndex && index !== imageIndex)
            :
            []

        const rows = fields.map((field, index) => [ field, data[ index ] ])

        return rows
    }

    render() {
        const { clicked } = this.props

        return (
            <React.Fragment>
                {
                    clicked ?
                    (
                        <Box width='100%' boxSizing='border-box' display='flex' flexDirection='column' justifyContent='flex-start' alignItems='center' border='1px solid grey' padding='8px' mt='8px' maxHeight='240px' overflow='auto'>
                            <Box width='100%'>
                                <DataInfo
                                    header='Details'
                                    rows={ this._getDataRows() }
                                />
                            </Box>
                            {
                                this._getImageLink() &&
                                <Box width='100%' height='200px' boxSizing='border-box' display='flex' flexDirection='column' justifyContent='center' alignItems='center' mt='8px' p='12px'>
                                    <Pannellum
                                        width='100%'
                                        height='100%'
                                        image={ this._getImageLink() }
                                        autoLoad={ true }
                                        showZoomCtrl={ false }
                                        keyboardZoom={ false }
                                        autoRotate={ -1 }
                                    />
                                </Box>
                            }
                        </Box>
                    ) :
                    (
                        null
                    )
                }
            </React.Fragment>
        )
    }
}

// Prop Types
SelectedDataInfo.propTypes = {
    clicked: PropTypes.object
}

SelectedDataInfo.defaultProps = {
    clicked: null
}

const mapStateToProps = state => ({
    datasets: state.keplerGl.map.visState.datasets,
    layers: state.keplerGl.map.visState.layers
})

export default connect(mapStateToProps, null)(SelectedDataInfo)