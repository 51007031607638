// Import Third-party Packages
import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'

class TabPanel extends React.PureComponent {
    render() {
        const { children, value, index, ...other } = this.props

        return (
            <Container
                disableGutters={ true }
                maxWidth={ false }
                role='tabpanel'
                id={ `tabpanel-${ index }` }
                aria-labelledby={ `tab-${ index }` }
                style={{ ...tabPanelContainerStyles, display: value === index ? 'block' : 'none' }}
                { ...other }
            >
                { value === index &&
                    (
                        <Box>
                            { children }
                        </Box>
                    )
                }
            </Container>
        )
    }
}

// JSS Styles
const tabPanelContainerStyles = {
    margin: '0px',
    padding: '8px',
    width: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflow: 'auto'
}

// Prop Types
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
}

export default TabPanel