// Import Third-party Packages
import React from 'react'
import PropTypes from 'prop-types'
import throttle from 'lodash/throttle'
import { connect } from 'react-redux'
import { Popup } from 'mapbox-gl'

// Import Components
import Container from '@material-ui/core/Container'
import KeplerGl from './KeplerGl'
import SidePanel from '../SidePanel/SidePanel'
import ExpandIconButton from '../common/ExpandIconButton'

// Import CSS Styles

// Import Actions
import { loadInitialCustomMap } from '../../store/actions/mapActions'
import { getReverseGeo } from '../../utils/reverseGeoUtils'
import { setLayerSizeWithZoomLevel } from '../../store/actions/layerActions'

// Global Variables
const MAPBOX_API_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_API_ACCESS_TOKEN

class KeplerGlMap extends React.PureComponent {
    state = {
        mapWidth: window.innerWidth,
        mapHeight: window.innerHeight,
        isSidePanelOpen: true,
        sidePanelTheme: 'dark',
        map: null,
        mapRef: null
    }

    componentDidMount() {
        // Handle Screen Resize
        this.onResize = throttle(this.updateAppSize, 0, { trailing: true })
        window.addEventListener('resize', this.onResize)

        // Load Initial Custom Map Without Data
        this.props.dispatch( loadInitialCustomMap() )
    }

    componentDidUpdate(prevProps) {
        const { keplerGl } = this.props
        const { mapRef } = this.state

        // If mapRef is null then set mapRef
        if(!mapRef) {
            const mapElement = document.querySelector('#kepler-gl__map .maps').firstChild
            if(mapElement && mapElement.className) {
                // Add click Event Listener to mapElement
                mapElement.addEventListener('contextmenu', this._handleOnMapRightClick)
                this.setState({ mapRef: mapElement })
            }
        }

        // If Map Zoom Changes
        if(prevProps.keplerGl.map && keplerGl.map) {
            if(prevProps.keplerGl.map.mapState.zoom !== keplerGl.map.mapState.zoom) {
                // Update Layer Sizes with zoom
                this.props.dispatch( setLayerSizeWithZoomLevel(keplerGl.map.mapState.zoom) )
            }
        }
    }

    componentWillUnmount() {
        const { mapRef } = this.state
        if(mapRef) {
            mapRef.removeEventListener('contextmenu', this._handleOnMapRightClick)
        }
    }

    // Handle Screen Resize
    updateAppSize = () => {
        this.setState({
            mapWidth: window.innerWidth,
            mapHeight: window.innerHeight
        })
    }

    // Hide Side Panel
    hideSidePanel = () => {
        this.setState({ isSidePanelOpen: false })
    }

    // Open Side Panel
    openSidePanel = () => {
        this.setState({ isSidePanelOpen: true })
    }

    // Handle Empty Map Right Click
    _handleOnMapRightClick = event => {
        if(event.target.tagName.toLowerCase() !== 'canvas' && event.target.id !== 'default-deckgl-overlay') {
            return
        }

        // Call Reverse Geo
        const { mousePos } = this.props.keplerGl.map.visState
        getReverseGeo(mousePos.coordinate[1], mousePos.coordinate[0])
            .then(data => {
                let reverseGeoResponse = null
                if(data.message) {
                    reverseGeoResponse = data

                } else {
                    reverseGeoResponse = data[0]
                }

                // Mapbox-Gl Popup
                const name = reverseGeoResponse.business_name ?
                    reverseGeoResponse.business_name :
                    reverseGeoResponse.place_name ?
                    reverseGeoResponse.place_name :
                    ''
                
                // Concat Address
                const Address = [
                    reverseGeoResponse.holding_number && reverseGeoResponse.holding_number.trim() ? 'House ' + reverseGeoResponse.holding_number.trim() : '',
                    reverseGeoResponse.road_name_number,
                    reverseGeoResponse.super_sub_area,
                    reverseGeoResponse.sub_area,
                    reverseGeoResponse.area
                ].filter(item => item && item.trim()).join(', ').trim()

                const postCode = reverseGeoResponse.postCode
                const pType = reverseGeoResponse.pType

                const reverseGeoPopupHTML = Address ?
                    `
                        <div class='reverse-geo-popup' style='display: flex; flex-direction: column; pointer-events: auto;'>
                            <div id='popup-close' style='width: 100%; display: flex; justify-content: flex-end'>
                                <button>x</button>
                            </div>
                            <div id='popup-content'>
                                <h3 id='title'>${ name ? name : '' }</h3>
                                <p id='address'><strong>Address:</strong> ${ Address ? Address : '' }</p>
                                <p id='post-code'><strong>Post Code:</strong> ${ postCode ? postCode : '' }</p>
                                <p id='pType'><strong>Type:</strong> ${ pType ? pType : '' }</p>
                            </div>
                        </div>
                    ` :
                    `
                        <div class='reverse-geo-popup' style='display: flex; flex-direction: column; pointer-events: auto;'>
                            <div id='popup-close' style='width: 100%; display: flex; justify-content: flex-end'>
                                <button>x</button>
                            </div>
                            <div id='popup-content'>
                                <p>Not Found</p>
                            </div>
                        </div>
                    `

                const popup = new Popup({ className: 'reverse-geo-popup', closeButton: false, closeOnClick: false})
                    .setLngLat(mousePos.coordinate)
                    .setHTML(reverseGeoPopupHTML)
                    .addTo(this.state.map)
                
                setTimeout(() => {
                    // Remove Popup
                    popup.remove()
                }, 10000)
            })
            .catch(err => console.error(err))
    }

    render() {
        const { mapWidth, mapHeight, isSidePanelOpen, sidePanelTheme } = this.state

        return (
            <Container disableGutters={ true } maxWidth={ false } style={ rootContainerStyles }>
                { isSidePanelOpen ?
                    ( <SidePanel theme={ sidePanelTheme } hideSidePanel={ this.hideSidePanel } /> ) :
                    ( <ExpandIconButton theme={ sidePanelTheme } onClick={ this.openSidePanel }/> )
                }

                <KeplerGl
                    id='map'
                    mapboxApiAccessToken={ MAPBOX_API_ACCESS_TOKEN }
                    width={ mapWidth }
                    height={ mapHeight }
                    getMapboxRef={ map => this.setState({ map: map ? map.getMap() : null }) }
                />
            </Container>
        )
    }
}

// JSS Styles
const rootContainerStyles = {
    margin: '0px',
    padding: '0px',
    width: '100vw',
    height: '100vh',
    maxWidth: '100vw',
    maxHeight: '100vh',
    overflow: 'hidden'
}

// Component PropTypes
KeplerGlMap.propTypes = {
    mapWidth: PropTypes.number.isRequired,
    mapHeight: PropTypes.number.isRequired
}

KeplerGlMap.defaultProps = {
    mapWidth: window.innerWidth,
    mapHeight: window.innerHeight
}

const mapStateToProps = state => ({
    keplerGl: state.keplerGl,
    app: state.app
})
const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(KeplerGlMap)