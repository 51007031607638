// Import Actions & Methods
import shortid from 'shortid'
import { processRowObject } from 'kepler.gl/processors'
import { addDataToMap, layerVisConfigChange } from 'kepler.gl/actions'
import { validateUploadedFiles } from '../../validators/fileValidators'
import { loadCsvFileDataToMap } from './csvFileDataActions'
import axios from 'axios'
import { getAddressColumnName, includesLatLongColumns } from '../../utils/dataUtils'
import { processWithRupantorAndDispatch } from './rupantorActions'
import * as ActionTypes from './actionTypes'
import { API_DATA_CONFIG } from '../../App.config'

// Load File Data To Map from Uploaded File or URL
export function loadFileDataToMap(files=null) {
    return (dispatch) => {
        // Send Data Request
        dispatch( setDataRequest() )

        if(files) {
            // If Files Uploaded, Validate Files
            validateUploadedFiles(files)
                .then(validFiles => {
                    // If No Valid Files Found
                    if(validFiles.length <= 0) {
                        dispatch( setDataLoadError({ message: 'File Type Not Supported!' }) )
                        return
                    }

                    // Load Uploaded Files into Datasets
                    validFiles.forEach((file, index) => {
                        if(file.csv) {
                            // Handle CSV Files
                            dispatch( loadCsvFileDataToMap(file.csv) )

                        } else if(file.json) {
                            // Handle JSON and GeoJSON Files

                        } else if(file.shp) {
                            // Handle Shape Files
                            // dispatch( loadShapeFileData(file, index) )

                        } else if(file.kml) {
                            // Handle KML Files
                            // dispatch( loadKmlFileData(file, index) )

                        } else {
                            // If No File Supported
                            dispatch( setDataLoadError({ message: 'File Type Not Supported!' }) )
                            return
                        }
                    })
                })
                .catch(err => console.warn(err))
        }
    }
}

// Load API Data To Map
export function loadApiDataToMap(apiUrl, source=null, params={}, headers={}) {
    return (dispatch, getState) => {
        // Send Data Request
        dispatch( setDataRequest() )
        dispatch( setIsApiFetching(true) )

        // API Call
        axios.get(apiUrl, {
            params,
            headers,
            timeout: 60000,
            timeoutErrorMessage: 'Connection timed out.',
            onDownloadProgress: progressEvent => {
                if(progressEvent.lengthComputable) {
                    dispatch( setApiTotalContentLength(progressEvent.total) )
                }

                dispatch( setApiTotalLoadedLength(progressEvent.loaded) )
            }
        })
            .then(res => {
                // Set Api Fetching to false
                dispatch( setIsApiFetching(false) )
                dispatch( setDataLoadSuccess(true) )

                const apiData = res.data.data

                if(apiData && apiData.length > 0) {
                    const fields = apiData.length > 0 ? Object.keys(apiData[0]) : []
                    // If Headers has `address` but no `lat` `long`, then process with Rupantor
                    const addressColumnName = getAddressColumnName(fields)
                    if(addressColumnName && !includesLatLongColumns(fields)) {
                        // If `latitude` or `longitude` doesn't exist in data, then fetch Additional Info using Rupantor API with mandatory `address` column

                        // Process with Rupantor engine action
                        dispatch( processWithRupantorAndDispatch(apiData, addressColumnName, null, apiUrl) )

                    } else if(includesLatLongColumns(fields)) {
                        // Proceed as usual Data Load

                        // Dispatch Updated data to Map
                        dispatch( dispatchApiDataToMap(apiData, source ? source : apiUrl) )

                    } else {
                        dispatch( setDataLoadError({ message: 'No Lat, Long or Address Found!' }) )
                        dispatch( setIsApiFetching(false) )
                    }

                } else {
                    dispatch( setDataLoadError({ message: 'No Data Found!' }) )
                    dispatch( setIsApiFetching(false) )
                }
            })
            .catch(err => {
                dispatch( setDataLoadError(err) )
                dispatch( setIsApiFetching(false) )
                console.error(err)
            })
    }
}

// Dispatch Add To Data Sources
export function addToDataSources(dataSource) {
    return dispatch => {
        dispatch({ type: ActionTypes.ADD_DATA_SOURCE, payload: dataSource })
    }
}

export function updateDataSource(dataSource) {
    return dispatch => {
        dispatch({ type: ActionTypes.UPDATE_DATA_SOURCE, payload: dataSource })
    }
}

export function removeDataSource(dataId) {
    return dispatch => {
        dispatch({ type: ActionTypes.REMOVE_DATA_SOURCE, payload: dataId })
    }
}

// Dispatch File Row Object Data to Kepler Map
export function dispatchFileDataToMap(inputData, file) {
    return dispatch => {
        // Build Dataset
        const dataId = shortid.generate()
        const dataLabel = file.name ? file.name.slice(0, file.name.lastIndexOf('.')) : `Dataset-` + dataId
        const dataInfo = { id: dataId, label: dataLabel }
        const data = processRowObject(inputData)
        const dataset = { info: dataInfo, data }
        const datasets = [ dataset ]

        // Options and Configs
        const options = { readOnly: true, centerMap: true }

        // Dispatch `addDataToMap`
        dispatch( addDataToMap({ datasets, options }) )

        // Dispatch to App Data Sources
        dispatch( addToDataSources({ dataId, source: file.name ? file.name : 'unknown', rows: data.rows.length }) )
    }
}

// Dispatch Api Row Object Data to Kepler Map
export function dispatchApiDataToMap(inputData, apiUrl) {
    return (dispatch, getState) => {
        // Build Dataset
        const dataId = shortid.generate()
        const dataLabel = apiUrl
        const dataInfo = { id: dataId, label: dataLabel }
        const data = processRowObject(inputData)
        const dataset = { info: dataInfo, data }
        const datasets = [ dataset ]

        // Options and Configs
        const options = { readOnly: true, centerMap: true }

        // Dispatch `addDataToMap`
        dispatch( addDataToMap({ datasets, options }) )

        // Dispatch to App Data Sources
        dispatch( addToDataSources({ dataId, source: apiUrl, rows: data.rows.length }) )

        // Set Color Range for OPS usage
        const { layers } = getState().keplerGl.map.visState
        layers.forEach(l => {
            const colorRange = {
                category: 'Barikoi',
                colors: API_DATA_CONFIG.colorRange,
                name: 'Trace Online/Offline',
                type: 'quantile'
            }

            dispatch( layerVisConfigChange(l, { colorRange }) )
        })
    }
}

////////////////////////////////
// Helper Actions & Functions //
////////////////////////////////

// Dispatch Set Data Request
export function setDataRequest() {
    return dispatch => {
        dispatch({ type: ActionTypes.DATA_REQUEST })
    }
}

// Dispactch Set Data Load Success
export function setDataLoadSuccess() {
    return dispatch => {
        dispatch({ type: ActionTypes.DATA_LOAD_SUCCESS })
    }
}

// Dispatch Set Data Load Error
export function setDataLoadError(err) {
    return dispatch => {
        dispatch({ type: ActionTypes.DATA_LOAD_ERROR, payload: err })
    }
}

// Dispatch Reset Data Error
export function resetDataError() {
    return dispatch => {
        dispatch({ type: ActionTypes.RESET_DATA_ERROR })
    }
}

// Set isApiFetching
function setIsApiFetching(value) {
    return dispatch => {
        dispatch({ type: ActionTypes.SET_IS_API_FETCHING, payload: value })
    }
}

// Set API Total Data Length
function setApiTotalContentLength(totalContentLength) {
    return dispatch => {
        dispatch({ type: ActionTypes.SET_API_TOTAL_CONTENT_LENGTH, payload: totalContentLength })
    }
}

// Set API Data Loaded Length
function setApiTotalLoadedLength(totalLoaded) {
    return dispatch => {
        dispatch({ type: ActionTypes.SET_API_DATA_LOADED_LENGTH, payload: totalLoaded })
    }
}