// Import Third-party Packages
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import Box from '@material-ui/core/Box'
import StyledSelectMenu from '../../common/StyledSelectMenu'

// Import Actions
import { layerTypeChange } from 'kepler.gl/actions'

class LayerSelectMenu extends React.PureComponent {
    state = {
        layerTypes: [
            { id: 0, value: 'point', label: 'Point' },
            // { id: 1, value: 'geojson', label: 'Polygon' },
            // { id: 2, value: 'grid', label: 'Grid' },
            // { id: 4, value: 'cluster', label: 'Cluster' },
            { id: 1, value: 'heatmap', label: 'Heatmap' },
            { id: 2, value: 'hexagon', label: 'Hexbin' }
        ]
    }

    // Handle On Layer Type Change
    handleOnLayerTypeChange = event => {
        const { layer } = this.props
        if(layer) {
            this.props.dispatch( layerTypeChange(layer, event.target.value) )
        }
    }

    render() {
        const { layerTypes } = this.state
        const { layerType } = this.props

        return (
            <Box width='100%' display='flex' flexWrap='wrap'>
                <StyledSelectMenu
                    label='Type'
                    value={ layerType }
                    menuItems={ layerTypes }
                    onChange={ this.handleOnLayerTypeChange }
                />
            </Box>
        )
    }
}

// Prop Types
LayerSelectMenu.propTypes = {
    layer: PropTypes.object.isRequired,
    layerType: PropTypes.string
}

LayerSelectMenu.defaultProps = {
    layerType: ''
}

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(null, mapDispatchToProps)(LayerSelectMenu)