import { load } from '@loaders.gl/core'
import { ZipLoader } from '@loaders.gl/zip'

// Validate File Types for Multi-upload and send valid files in an array format
export function validateUploadedFiles(files) {
    return new Promise((resolve, reject) => {
        // Check for empty files
        if(files && files.length > 0) {
            // All Files Storage
            let allFiles = []

            // Get Shape File in obj format and add to allFiles
            const shapeFiles = getShapeFiles(files)
            allFiles = allFiles.concat(shapeFiles)

            // Get KML Files and add to allFiles
            const kmlFiles = getKmlFiles(files)
            allFiles = allFiles.concat(kmlFiles)

            // Get CSV Files and add to allFiles
            const csvFiles = getCsvFiles(files)
            allFiles = allFiles.concat(csvFiles)

            // Get JSON Files and add to allFiles
            const jsonFiles = getJsonFiles(files)
            allFiles = allFiles.concat(jsonFiles)

            // Get Zip Files
            const zipFiles = getZipFiles(files)

            // Get Kmz Files
            const kmzFiles = getKmzFiles(files)
            
            // Get Parsed Zip Files from .zip and .kmz if available
            if(zipFiles.length > 0 || kmzFiles.length > 0) {
                Promise.all([ getParsedZipToShapeFiles(zipFiles), getParsedKmzToKmlFiles(kmzFiles) ])
                    .then(results => {
                        results.forEach(result => {
                            // For each type of zip results
                            result.forEach(item => {
                                // Add to allFiles
                                allFiles = allFiles.concat(item)
                            })
                        })

                        resolve(allFiles)
                    })
                    .catch(err => { throw(err) })
                    
            } else {
                resolve(allFiles)
            }

        } else {
            reject('Empty Files')
        } 
    })
}

// Get Zip Files from uploaded Files
function getZipFiles(files) {
    const zipExtensions = ['zip', 'ZIP']
    const zipFiles = files.filter(file => zipExtensions.includes(file.name.split('.').pop()))

    return zipFiles
}

// Parse ZIP Files to Shape Files
function getParsedZipToShapeFiles(zipFiles) {
    // Extract Shape Files from Zip Files and Add to Shape Files
    let loadPromises = []
    let resolvedZipFiles = []
    zipFiles.forEach(zipFile => {
        loadPromises.push(load(zipFile, ZipLoader))
    })

    return Promise.all(loadPromises)
        .then(zipResults => {
            zipResults.forEach(item => {
                let shapeFile = {
                    shp: null,
                    dbf: null
                }

                Object.keys(item).forEach(key => {
                    if(key.endsWith('.shp') || key.endsWith('.SHP')) {
                        shapeFile.shp = item[key]

                    } else if(key.endsWith('.dbf') || key.endsWith('.DBF')) {
                        shapeFile.dbf = item[key]
                    }
                })

                resolvedZipFiles.push(shapeFile)
            })

            return resolvedZipFiles
        })
        .then(resolvedZipFiles => {
            // Filter out null shapefiles
            let prevLength = resolvedZipFiles.length

            resolvedZipFiles = resolvedZipFiles.filter(file => file.shp)

            // Throw warning if some filtered out
            if(prevLength !== resolvedZipFiles.length) {
                console.warn('One or more ZIP File doesn\'t include any SHP file!')
            }

            return resolvedZipFiles
        })
        .catch(err => { throw(err) })
}

// Get Shape Files from uploaded Files
function getShapeFiles(files) {
    let shapeFiles = []
    let fileFullNameSplit = []
    let fileName = ''
    let extension = ''

    files.forEach(file => {
        fileFullNameSplit = file.name.split('.')
        extension = fileFullNameSplit.pop()
        fileName = fileFullNameSplit.join('.')

        if(extension === 'shp' || extension === 'SHP') {
            // Check if dbf file already exists
            const indexDbf = shapeFiles.findIndex(item => item.dbf && item.dbf.name.slice(0, -4) === fileName)
            if(indexDbf >= 0) {
                // If exists add shp file
                shapeFiles[indexDbf].shp = file

            } else {
                // If doesn't exist, add new obj with file
                shapeFiles.push({ shp: file, dbf: null })
            }

        } else if(extension === 'dbf' || extension === 'DBF') {
            // Check if shp file already exists
            const indexShp = shapeFiles.findIndex(item => item.shp.name.slice(0, -4) === fileName)
            if(indexShp >= 0) {
                // If exists add dbf file
                shapeFiles[indexShp].dbf = file

            } else {
                // If doesn't exist, add new obj with file
                shapeFiles.push({ shp: null, dbf: file })
            }
        }
    })

    let prevLength = shapeFiles.length

    // Filter out objects with Valid shp file
    shapeFiles = shapeFiles.filter(file => file.shp)

    // Throw warning if some filtered out
    if(prevLength !== shapeFiles.length) {
        console.warn('One or more DBF File doesn\'t have an SHP file attached!')
    }

    return shapeFiles
}

// Get KML Files
function getKmlFiles(files) {
    let kmlFiles = []
    let extension = ''

    files.forEach(file => {
        extension = file.name.split('.').pop()

        if(extension === 'kml' || extension === 'KML') {
            kmlFiles.push({ kml: file })
        }
    })

    return kmlFiles
}

// Get KMZ Files
function getKmzFiles(files) {
    const kmzExtensions = ['kmz', 'KMZ']
    const kmzFiles = files.filter(file => kmzExtensions.includes(file.name.split('.').pop()))

    return kmzFiles
}

// Extract .kml files from kmz files
function getParsedKmzToKmlFiles(kmzFiles) {
    // Extract KML Files from KMZ Files and Add to KML Files
    let loadPromises = []
    let resolvedKmzFiles = []
    kmzFiles.forEach(kmzFile => {
        loadPromises.push(load(kmzFile, ZipLoader))
    })

    return Promise.all(loadPromises)
        .then(kmzResults => {
            kmzResults.forEach(item => {
                let kmlFile = {
                    kml: null
                }

                Object.keys(item).forEach(key => {
                    if(key.endsWith('.kml') || key.endsWith('.KML')) {
                        kmlFile.kml = item[key]
                    }
                })

                resolvedKmzFiles.push(kmlFile)
            })
            
            return resolvedKmzFiles
        })
        .then(resolvedKmzFiles => {
            // Filter out null kml Files
            let prevLength = resolvedKmzFiles.length

            resolvedKmzFiles = resolvedKmzFiles.filter(file => file.kml)
            
            // Throw warning if some filtered out
            if(prevLength !== resolvedKmzFiles.length) {
                console.warn('One or more KMZ File doesn\'t include any KML file!')
            }

            return resolvedKmzFiles
        })
        .catch(err => { throw(err) })
}

// Get CSV Files
function getCsvFiles(files) {
    let csvFiles = []
    let extension = ''

    files.forEach(file => {
        extension = file.name.split('.').pop()

        if(extension === 'csv' || extension === 'CSV' || extension === 'xls' || extension === 'XLS' || extension === 'xlsx' || extension === 'XLSX') {
            csvFiles.push({ csv: file })
        }
    })

    return csvFiles
}

// Get JSON Files
function getJsonFiles(files) {
    let jsonFiles = []
    let extension = ''

    files.forEach(file => {
        extension = file.name.split('.').pop()

        if(extension === 'json' || extension === 'JSON') {
            jsonFiles.push({ json: file })
        }
    })

    return jsonFiles
}