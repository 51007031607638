import { combineReducers } from 'redux'
import dataReducer from './dataReducer'
import layerReducer from './layerReducer'
import filterReducer from './filterReducer'
import reverseGeoReducer from './reverseGeoReducer'
import authReducer from './authReducer'

const appReducer = combineReducers({
    dataState: dataReducer,
    layerState: layerReducer,
    filterState: filterReducer,
    reverseGeo: reverseGeoReducer,
    auth: authReducer
})

export default appReducer