import axios from 'axios'

const REVERSE_GEO_API = 'https://api.bmapsbd.com/reverse/without/auth'

// Reverse Geo API function
export function getReverseGeo(latitude, longitude) {
    return axios.get(REVERSE_GEO_API, { params: { latitude, longitude } })
        .then(res => {
            return res.data
        })
        .catch(err => { throw(err) })
}