import * as ActionTypes from '../actions/actionTypes'

const initialState = {
    filtersValueRanges: []
}

const filterReducer = (state=initialState, action) => {
    let { filtersValueRanges } = state

    switch(action.type) {
        case ActionTypes.SET_FILTER_VALUE_RANGE:
            if(filtersValueRanges.length <= action.payload.filterIndex) {
                return {
                    ...state,
                    filtersValueRanges: [ ...state.filtersValueRanges, action.payload.range ]
                }

            } else if(filtersValueRanges.length > action.payload.filterIndex) {
                filtersValueRanges[action.payload.filterIndex] = action.payload.range
                return {
                    ...state,
                    filtersValueRanges
                }

            }

            return state

        case ActionTypes.REMOVE_FILTER_VALUE_RANGE:
            filtersValueRanges = filtersValueRanges.filter((item, index) => index !== action.payload.filterIndex)

            return {
                ...state,
                filtersValueRanges
            }

        default:
            return state
    }
}

export default filterReducer