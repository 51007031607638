// Import Third-party Packages
import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import Box from '@material-ui/core/Box'

class WhiteSpace extends React.PureComponent {
    render() {
        const { width, height } = this.props

        return (
            <Box style={{ width, height }} />
        )
    }
}

WhiteSpace.propTypes = {
    width: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    heigth: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
}

WhiteSpace.defaultProps = {
    width: '100%',
    height: '24px'
}

export default WhiteSpace