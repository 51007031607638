// Import Third-party Packages
import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

class ExpandIconButton extends React.PureComponent {
    state = {
        isHovered: false,
        background: 'rgba(56, 64, 78, 0.8)',
        color: '#fff'
    }

    componentDidMount() {
        const { theme } = this.props
        this._setTheme(theme)
    }

    componentDidUpdate(prevProps, prevState) {
        const { theme } = this.props
        const { isHovered } = this.state

        // If `theme` changes from props
        if(prevProps.theme !== theme) {
            this._setTheme(theme)
        }

        // If `isHovered` state changes
        if(prevState.isHovered !== isHovered) {
            let background = ''

            if(isHovered) {
                background = theme === 'light' ? 'rgba(255, 255, 255, 0.7)' : 'rgba(56, 64, 78, 0.9)'

            } else {
                background = theme === 'light' ? 'rgba(255, 255, 255, 0.8)' : 'rgba(56, 64, 78, 0.8)'
            }

            this.setState({ background })
        }
    }

    // Change `isHovered` on hover
    _setIsHoveredTrue = () => {
        this.setState({ isHovered: true })
    }

    _setIsHoveredFalse = () => {
        this.setState({ isHovered: false })
    }

    // Set Theme based on props
    _setTheme = theme => {
        let background = ''
        let color = ''

        switch(theme) {
            case 'light':
                background = 'rgba(255, 255, 255, 0.8)'
                color = 'rgba(56, 64, 78)'
                break
            case 'dark':
                background = 'rgba(56, 64, 78, 0.8)'
                color = '#fff'
                break
            default:
                background = 'rgba(56, 64, 78, 0.8)'
                color = '#fff'
        }

        this.setState({ background, color })
    }

    render() {
        const { onClick } = this.props
        const { background, color } = this.state

        return (
            <Box style={ expandIconContainerStyles } onMouseEnter={ this._setIsHoveredTrue } onMouseLeave={ this._setIsHoveredFalse }>
                <IconButton
                    size='small'
                    onClick={ onClick }
                    style={{ ...expandIconButtonStyles, background }}
                >
                    <ChevronRightIcon style={{ color }} />
                </IconButton>
            </Box>
        )
    }
}

// JSS Styles
const expandIconContainerStyles = {
    position: 'absolute',
    zIndex: '99',
    width: '32px',
    height: '32px',
    pointerEvents: 'auto',
    left: '4px',
    top: '4px'
}

const expandIconButtonStyles = {
    borderRadius: '4px'
}

ExpandIconButton.propTypes = {
    theme: PropTypes.oneOf([ 'light', 'dark' ]),
    onClick: PropTypes.func.isRequired
}

ExpandIconButton.defaultProps = {
    theme: 'dark'
}

export default ExpandIconButton