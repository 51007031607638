// BASE URL
export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL || ''

// App Configurations
export const MAP_CONFIG = {
    MAPBOX_TOKEN: process.env.REACT_APP_MAPBOX_API_ACCESS_TOKEN || null,
}

// Tracker Configurations
export const REVERSE_GEO = {
    KEY: 'mykey',
    HOST: 'backend.barikoi.com',
    PORT: 6002,
    AUTH_ENDPOINT: 'https://backend.barikoi.com:8888/api/broadcasting/auth',
    CLUSTER: 'ap2',
    CHANNEL: 'barikoi_technologies_limited_21796',
    EVENT: 'barikoi_technologies_limited_catena_channel',
    DATA_ID: 'reverse_geo_data',
    DATA_LABEL: 'Reverse Geo Data',
    PRELOAD_API: 'https://backend.barikoi.com:8888/api/v1/catena/reverse-geo?api_key=MTcyOTpFUjlYM0pBMk9O',
    API_AUTH_TOKEN: process.env.REACT_APP_API_AUTH_TOKEN || null
}

export const GEO_SERVER = {
    ROUTE_OPTIMIZATION_API: 'https://geoserver.bmapsbd.com/vh/optimized_route',
    RAILWAY_ROUTE_API: 'https://geoserver.bmapsbd.com/osrm/train',
    RIVER_ROUTE_API: 'https://geoserver.bmapsbd.com/osrm/river',
    ROUTE_DATA_ID: 'optimized_route',
    ROUTE_DATA_LABEL: 'Optimized Route',
    WAYPOINTS_DATA_ID: 'route_waypoints',
    WAYPOINTS_DATA_LABEL: 'Route Waypoints'
}

// Authentication Configs
export const AUTH = {
    LOGIN_API: `${ BASE_API_URL }/login`,
    JWT_VALIDATION_API: `${ BASE_API_URL }/auth/user`
}

// Custom configs for API data
export const API_DATA_CONFIG = {
    AREA_API: `${ BASE_API_URL }/data-freshness?area=`,
    AVAILABLE_AREAS: [
        "1 No Kalma Bazar",
        "2 No Railgate",
        "Adabor",
        "Adarshanagar",
        "Aftabnagar",
        "Agargaon",
        "Agrabad",
        "Akhalia",
        "Akua",
        "Akua Madrasha Quarter",
        "Akua Moral Para",
        "Ambarkhana",
        "Amberkhana",
        "Amin Bazar",
        "Amin Bazar",
        "Amlapara",
        "Amlapara",
        "Amlapara",
        "Anandapur",
        "Anderkilla",
        "Anowara",
        "Armanitola",
        "Aruna Polli",
        "Ashtadhar",
        "Ashulia",
        "Askarabad",
        "Athrabari Building",
        "Aturar Dipo",
        "Azampur",
        "Azimpur",
        "BAU RESIDENIAL AREA",
        "BAU Staff Quarter",
        "Babna Point",
        "Babu Bazar",
        "Baburail",
        "Badam Bagicha",
        "Badda",
        "Badurtola",
        "Baganbari",
        "Baghbari",
        "Baghmara",
        "Bahaddarhat",
        "Baira (Kewatkhali)",
        "Bakalia",
        "Bakshi Bazar",
        "Bakterpur",
        "Balaganj",
        "Baluchor",
        "Banagram",
        "Banani",
        "Banasree",
        "Bandar Bazar",
        "Bandarbazar",
        "Bangla Motor",
        "Bangshal",
        "Bank Colony",
        "Banshkhali",
        "Bara Balimehar",
        "Baria",
        "Baridhara",
        "Barua",
        "Barutkhana",
        "Basan",
        "Bashabo",
        "Bashbari",
        "Bashundhara",
        "Baunia",
        "Bayejid Bostami",
        "Bayezid",
        "Beani Bazar",
        "Beraid",
        "Bhabkhali",
        "Bhakurta",
        "Bhashantek",
        "Bhataliya",
        "Bhuiyarbagh",
        "Bhuiyarbagh",
        "Bibir Haat",
        "Bijoynagar",
        "Biralia",
        "Bishwanath",
        "Boalkhali",
        "Bolashpur",
        "Borar Char",
        "Boro Bazar",
        "Boro Kali Bari",
        "Borobazar",
        "Brahman Shashon",
        "Buet Campus",
        "CDA",
        "Chalibandar",
        "Chandanaish",
        "Chandgaon",
        "Chankharpul",
        "Char Ishwardia",
        "Char Nilakshmia",
        "Chara Dighirpar",
        "Charpara",
        "Chashara",
        "Chawkbazar",
        "Chawkbazar",
        "Chayabithi",
        "Chhararpar",
        "Chittagong Port",
        "Chittagong Sadar",
        "Choto Bazar",
        "Chowhatta",
        "Chowkidekhi",
        "Circuit House Play Ground",
        "Colonel Hat",
        "Companiganj",
        "Dadar Ghat",
        "Dailpotti",
        "Dakkhin Para",
        "Dakkhin Shurma",
        "Dakkhin Surma",
        "Dakkhinkhan",
        "Dakshin Chayabithy",
        "Dakshin Surma",
        "Dampara",
        "Dapunia",
        "Dargah Gate",
        "Dargah Mahalla",
        "Dargah Moholla",
        "Dariapara",
        "Dariyapara",
        "Darussalam",
        "Dayaganj",
        "Demra",
        "Deobhog",
        "Deobhog",
        "Dewan Bazar",
        "Dewanhat",
        "Dhaka Cantonment",
        "Dhaka University Campus",
        "Dhamrai",
        "Dhamsana",
        "Dhanmondi",
        "Dharenda",
        "Dholadia",
        "Dholairpar",
        "Dhopadighir Par",
        "Dhour",
        "Digarkanda",
        "Digu Babur Bazar",
        "Dit",
        "Dohar",
        "Donchamber",
        "Donia",
        "Double Mooring",
        "Dud Mahal",
        "Duldul Slum",
        "Dumni",
        "Durgabari",
        "Eidgah",
        "Elephant Road",
        "Enayet Bazar",
        "Epz",
        "Eskaton",
        "Fakirapul",
        "Farmgate",
        "Fatikchhari",
        "Fatullah",
        "Fenchuganj",
        "Ferozshah",
        "Fireghat",
        "Fulbaria",
        "Gabtoli",
        "Gachha",
        "Ganginar Par",
        "Gashitula",
        "Gazipur",
        "Gazipur Sadar",
        "Genda",
        "Gendaria",
        "Ghagra",
        "Ghasitula",
        "Golabganj",
        "Golachipa",
        "Golapbagh",
        "Golgonda",
        "Golpukurpar",
        "Gopibagh",
        "Goran",
        "Gotatikar",
        "Gowainghat",
        "Gowaitula",
        "Gulisthan",
        "Gulshan",
        "Halishahar",
        "Hamjarbagh",
        "Hathazari",
        "Hatirpool",
        "Hawapara",
        "Hazaribagh",
        "Hazibag",
        "Hazigonj",
        "Hazipara",
        "Horizon Sweeper Colony",
        "Imandipur",
        "Isdair",
        "Islambagh",
        "Islampur",
        "Jaintiapur",
        "Jalalabad",
        "Jalalabad",
        "Jalalabad Residential Area",
        "Jaleshwar",
        "Jalopara",
        "Jamal Khan",
        "Jamshing",
        "Jamtola",
        "Jatrabari",
        "Jigatola",
        "Jollarpar",
        "Jotorpur",
        "Joydebpur",
        "Jurain",
        "Kachijhuli",
        "Kadamtali",
        "Kadamtali",
        "Kadamtali",
        "Kafrul",
        "Kajal Shah",
        "Kakrail",
        "Kalabagan",
        "Kalachandpur",
        "Kalibari",
        "Kalighat",
        "Kalir Bazar",
        "Kallyanpur",
        "Kamal Gor",
        "Kamalapur",
        "Kamrangirchar",
        "Kanaighat",
        "Kanchpur",
        "Kanishail",
        "Kashor",
        "Kastoghor",
        "Katabon",
        "Katalganj",
        "Kathalbagan",
        "Kathgola Bazar",
        "Kaundia",
        "Kawran Bazar",
        "Kayaltia",
        "Kazir Bazar",
        "Kazir Dewri",
        "Kazitula",
        "Keraniganj",
        "Kewapara",
        "Kewatkhali",
        "Khadim Para",
        "Khadimnagar",
        "Khagdahar",
        "Khandaura",
        "Khanpur",
        "Khasdabir",
        "Khilgaon",
        "Khilkhet",
        "Khojarkhala",
        "Khuliapara",
        "Khulshi",
        "Kistopur",
        "Kolapara",
        "Kolbakhani",
        "Korerpara",
        "Kotwali",
        "Kotwali",
        "Kotwali",
        "Kristopur",
        "Kuarpar",
        "Kumar Para",
        "Kumar para",
        "Kumargaon",
        "Kuril",
        "Kushighat",
        "Kushtia",
        "Kushtia",
        "Laladighir Par",
        "Lalbagh",
        "Lalkhan Bazar",
        "Lalmatia",
        "Lamabazar",
        "Lamapara",
        "Lohagara",
        "Lohar Para",
        "Luxmipura",
        "Machu Dighirpar",
        "Madarbari",
        "Madartek",
        "Madhu Shahid",
        "Madina Market",
        "Maizpara",
        "Majortila",
        "Majumdar Para",
        "Majumder Para",
        "Malibagh",
        "Manda",
        "Maniknagar",
        "Masdair",
        "Masimpur",
        "Maskanda",
        "Maskanda",
        "Mechua Bazar",
        "Mehedibagh",
        "Mendibagh",
        "Meradia",
        "Metro Hall",
        "Mirabaza",
        "Mirabazar",
        "Mirboxtula",
        "Mirer Moydan",
        "Mirer Moydhan",
        "Mirjarpul",
        "Mirpur",
        "Mirsharai",
        "Mirza Jangal",
        "Mirzajangal",
        "Mirzapur",
        "Mirzapur",
        "Mission Para",
        "Mitford",
        "Mmch Stuff Quarter",
        "Modina Market",
        "Moghbazar",
        "Mohakhali",
        "Mohammadpur",
        "Mohammadpur",
        "Mohora",
        "Mojumdari",
        "Mollapara",
        "Mondol Para",
        "Mondol Para",
        "Monsurabad",
        "Morakhola",
        "Motijheel",
        "Mouchak",
        "Mugda",
        "Munshipara",
        "Muradnagar",
        "Muradpur",
        "Mushipara",
        "Mymensingh",
        "Nadda",
        "Nagar Khanpur",
        "Naiorpool",
        "Nandipara",
        "Narayanganj Sadar",
        "Narayanganj Sadar",
        "Narayanganj Sadar",
        "Nasirabad",
        "Natok Ghar Lane",
        "Naumahal",
        "Nawab Katra",
        "Nawabganj",
        "Nawabpur",
        "Naya Bazar",
        "Nayamati",
        "Nayanagar",
        "Nayapara",
        "Nayasharak",
        "Nazir Para",
        "Neemtola",
        "New Eskaton",
        "New Market",
        "New Mooring",
        "Nikunja",
        "Nilkhet",
        "Nitaiganj",
        "Noluya",
        "Notun Bazar",
        "Notun Bazar",
        "Nowagaon",
        "Nowapara",
        "Nowmohal",
        "Old Town",
        "Oxygen",
        "Pahartali",
        "Paikpara",
        "Paltan",
        "Palton",
        "Panchlaish",
        "Panchlish",
        "Panthapath",
        "Paranganj",
        "Paribagh",
        "Patenga",
        "Patgudam",
        "Pathalia",
        "Pathantula",
        "Patiya",
        "Pir Mahalla",
        "Police Line",
        "Pondit Para",
        "Ponitula",
        "Pubail",
        "Puraton Court",
        "Purbachal",
        "Purohit Para",
        "Rahattar Pool",
        "Rail Gate",
        "Rajarbagh",
        "Rajashon",
        "Ramer Dighirpar",
        "Ramna",
        "Rampura",
        "Rangunia",
        "Raozan",
        "Ray Shaheb Bazar",
        "Rayer Bazar",
        "Rayerbagh",
        "Raynagar",
        "Rikabi Bazar",
        "Rothkhola",
        "Rowfabad",
        "Sabujbagh",
        "Sadarghat",
        "Sadarghat",
        "Sandwip",
        "Sarulia",
        "Satkania",
        "Savar",
        "Savar",
        "Savar",
        "Sayedabad",
        "Shadatikor",
        "Shadur Bazar",
        "Shagor Dighir Par",
        "Shaha Para",
        "Shahbagh",
        "Shahi Eidgah",
        "Shahidbagh",
        "Shahjadpur",
        "Shahjahanpur",
        "Shahjalal University Of Science & Technology",
        "Shahjalal Upashahar",
        "Shajalalal Upashahar",
        "Shamibagh",
        "Shamimabad",
        "Shamser Para",
        "Shanir Akhra",
        "Shantibagh",
        "Shantinagar",
        "Shawdagor Tula",
        "Shegunbagicha",
        "Shehora",
        "Sheikhghat",
        "Sher E Bangla Nagar",
        "Shibganj",
        "Shikarikanda",
        "Shikarikanda",
        "Shimulia",
        "Shimultoly",
        "Shitalakkhya",
        "Shitalakkhya",
        "Shivbari",
        "Shobhanighat",
        "Shohidnogor",
        "Sholokbahar",
        "Sholosohor",
        "Shonarpara",
        "Shyamoli",
        "Shyampur",
        "Siddheshwari",
        "Siddhirganj",
        "Siddik Bazar",
        "Sirta",
        "Sitakunda",
        "Sobhanighat",
        "Sonarpara",
        "Soudagor Tola",
        "South Banasree",
        "South Keraniganj",
        "Subid Bazar",
        "Sutarpara",
        "Sutrapur",
        "Swadeshi Bazar",
        "Sylhet",
        "Sylhet Biman Bondar",
        "Sylhet Jalalabad Cantonment",
        "Sylhet Khadimnagar",
        "Sylhet Sadar",
        "Taltola",
        "Taltola",
        "Tamak Potti",
        "Tamakpotti",
        "Tan Bazar",
        "Tanbazar",
        "Tangail Bus arminal",
        "Tankir Par",
        "Tanti Para",
        "Tejgaon",
        "Teli Para",
        "Tetuljhora",
        "Thana Pukurpar",
        "Tikatuli",
        "Tilagor",
        "Tolla",
        "Town Hall",
        "Trishal Bus Stand",
        "Trunk Potti",
        "Tultikor",
        "Turag",
        "Ukilpara",
        "Upazila Parishad",
        "Uttar Cyabithy",
        "Uttara",
        "Uttara Residential Area",
        "Uttarkhan",
        "Vagolpur",
        "Varthokhala",
        "Varthokhola",
        "Vatara",
        "Vatikashor",
        "Vatpara",
        "Wapda Colony",
        "Wari",
        "Yearpur",
        "Zakiganj",
        "Zindabazar"
    ],
    colorRange: [
        '#e4816a',
        '#5dc655',
        '#b15fd4',
        '#9ab635',
        '#6666d6',
        '#4a9b3c',
        '#d149ac',
        '#5ec28b',
        '#da3e7f',
        '#408147',
        '#d4404f',
        '#42c3c2',
        '#d04f2a',
        '#50a2d1',
        '#dd9130',
        '#5b64ab',
        '#c0ab39',
        '#944f99',
        '#597724',
        '#8496e2',
        '#72671e',
        '#d588c8',
        '#a1ba70',
        '#a04a72',
        '#348a6e',
        '#e07d93',
        '#8d8c49',
        '#a6484e',
        '#d3a167',
        '#9c5e2a'
    ]
}