import papa from 'papaparse'
import { processWithRupantorAndDispatch } from './rupantorActions'
import { dispatchFileDataToMap, setDataLoadError } from './dataActions'
import { trim as _trim } from 'lodash'
import { getAddressColumnName, includesLatLongColumns } from '../../utils/dataUtils'

// Process and Load CSV File Data to Map from uploaded File
export function loadCsvFileDataToMap(file) {
    return dispatch => {
        // Read CSV File using PapaParse
        papa.parse(file, {
            header: true,
            skipEmptyLines: 'greedy',
            transformHeader: column => {
                return column.trim()
            },
            transform: value => {
                return trimAndFormatString(value)
            },
            complete: results => {
                // If Headers has `address` but no `lat` `long`, then process with Rupantor
                const addressColumnName = getAddressColumnName(results.meta.fields)
                if(addressColumnName && !includesLatLongColumns(results.meta.fields)) {
                    // If `latitude` or `longitude` doesn't exist in data, then fetch Additional Info using Rupantor API with mandatory `address` column

                    // Process with Rupantor engine action
                    dispatch( processWithRupantorAndDispatch(results.data, addressColumnName, file) )

                } else if(includesLatLongColumns(results.meta.fields)) {
                    // Proceed as usual Data Load

                    // Dispatch Updated data to Map
                    dispatch( dispatchFileDataToMap(results.data, file) )

                } else {
                    dispatch( setDataLoadError({ message: 'No Lat, Long or Address Found!' }) )
                }
            }
        })
    }
}

//////////////////////
// Helper Functions //
//////////////////////

// Trim Data String to remove whitespaces and extra quotations
function trimAndFormatString(value) {
    const valueType = typeof value
    if(valueType === 'string') {
        // Trim Value
        if(value.startsWith(' ') || value.endsWith(' ')) {
            value = _trim(value)
        }

        // Trim Extra Quotations
        if(value.startsWith(`"`) || value.endsWith(`"`)) {
            value = _trim(value, `"`)
        }

        // Trim again inside quotations
        if(value.startsWith(' ') || value.endsWith(' ')) {
            value = _trim(value)
        }
    }

    return value
}