// Import Third-party Packages
import { KeplerGlSchema } from 'kepler.gl/schemas'
import { receiveMapConfig, mapStyleChange, mapConfigChange } from 'kepler.gl/actions'

// Import Map Configs
import initial_custom_map_config from '../../configs/initial_custom_map_config.json'

// Import Constants
import { VALID_MAP_STYLE_TYPES } from '../../constants/mapConstants'

// Load Initial Custom Map with Two Default Styles such as Mapbox Satellite and Barikoi Map Styles
export function loadInitialCustomMap() {
    return dispatch => {
        const initialCustomMapConfig = initial_custom_map_config
        const parsedInitialCustomMapConfig = KeplerGlSchema.parseSavedConfig(initialCustomMapConfig)
        dispatch( receiveMapConfig(parsedInitialCustomMapConfig) )
    }
}

// Set Current Map Style i.e Sattelite, Barikoi One Map
export function setCurrentMapStyle(mapStyleType) {
    return (dispatch, getState) => {
        // Check Validity
        if(!VALID_MAP_STYLE_TYPES.includes(mapStyleType)) {
            throw(new Error(`Invalid Map Style Type. Choose from ${ JSON.stringify(VALID_MAP_STYLE_TYPES) }`))
        }

        // Check if already selected
        const { styleType } = getState().keplerGl.map.mapStyle
        if(styleType === mapStyleType) {
            return
        }

        dispatch( mapStyleChange(mapStyleType) )

        // Set Map Visible Layers
        const { visibleLayerGroups } = getState().keplerGl.map.mapStyle
        Object.keys(visibleLayerGroups).forEach(key => {
            if(key !== '3d building') {
                visibleLayerGroups[key] = true
            }
        })

        dispatch( mapConfigChange({ visibleLayerGroups }) )
    }
}