// Import Third-party Packages
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import Box from '@material-ui/core/Box'
import StyledSelectMenu from '../../common/StyledSelectMenu'

// Import Actions
import { setFilter } from 'kepler.gl/actions'

class FilterValueSelectMenu extends React.PureComponent {
    // Handle On Filter Field Select
    handleOnFilterValueSelect = event => {
        const { filterIndex } = this.props
        this.props.dispatch( setFilter(filterIndex, 'value', [ event.target.value ]) )
    }

    render() {
        const { filter, filterIndex } = this.props
        const { filtersValueRanges } = this.props.filterState

        return (
            <Box width='100%'>
                <StyledSelectMenu
                    label='Value'
                    value={ filter.value && filter.value.length > 0 ? filter.value[0] : '' }
                    menuItems={ filtersValueRanges.length > filterIndex ? filtersValueRanges[filterIndex] : [] }
                    onChange={ this.handleOnFilterValueSelect }
                />
            </Box>
        )
    }
}

// Prop Types
FilterValueSelectMenu.propTypes = {
    filter: PropTypes.object.isRequired,
    filterIndex: PropTypes.number.isRequired
}

const mapStateToProps = state => ({
    filterState: state.app.filterState
})
const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(FilterValueSelectMenu)