// Import Third-party Packages
import React from 'react'
import { connect } from 'react-redux'
import { API_DATA_CONFIG } from '../../../App.config'

// Import Components
import { withStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import PublishIcon from '@material-ui/icons/Publish'
// import TextField from '@material-ui/core/TextField'
import LinearProgress from '@material-ui/core/LinearProgress'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import StyledTable from '../../common/StyledTable'
import SelectedDataInfo from './SelectedDataInfo'
import StyledSelectMenu from '../../common/StyledSelectMenu'

// Import Actions
import { removeDataset } from 'kepler.gl/actions'
import { loadFileDataToMap, loadApiDataToMap, setDataLoadSuccess, resetDataError, removeDataSource } from '../../../store/actions/dataActions'
import { optimizeDataWithGeoServer, getRailwayRoute, getRiverRoute, getBicycleRoute } from '../../../store/actions/geoServerActions'

class DatasetsTabPanel extends React.PureComponent {
    state = {
        areaOptions: [],
        apiUrl: ''
    }

    componentDidMount() {
        // Set Area Options
        const areaOptions = this._getAreaOptions()
        this.setState({ areaOptions })
    }

    componentDidUpdate(prevProps) {
        const prevDatasetsLength = prevProps.visState.datasets ? Object.keys(prevProps.visState.datasets).length : 0
        const datasetsLength = this.props.visState.datasets ? Object.keys(this.props.visState.datasets).length : 0

        // Check datasets changes to determine progress bar state
        if(prevDatasetsLength !== datasetsLength) {
            this.props.dispatch( setDataLoadSuccess() )
        }
    }

    // Handle File Upload
    handleFileUpload = event => {
        // Grab Uploaded Files
        const files = [].slice.call(event.target.files)
        
        // Dispatch Add Data To Map
        this.props.dispatch( loadFileDataToMap(files) )
    }

    // Handle Snackbar Close
    handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        this.props.dispatch( resetDataError() )
    }

    // Handle Remove Dataset
    handleRemoveDataset = (event, dataId) => {
        // Dispatch Remove Dataset
        this.props.dispatch( removeDataset(dataId) )

        // Remove from Data Sources
        this.props.dispatch( removeDataSource(dataId) )

        document.getElementById('file-upload').value = null
    }

    // Handle Input On Change
    handleInputOnChange = event => {
        this.setState({ [ event.target.name ]: event.target.value })
    }

    // Handle API URL Submission
    handleApiUrlSubmission = event => {
        event.preventDefault()

        const { apiUrl } = this.state
        this.props.dispatch( loadApiDataToMap(apiUrl, `Area Data (${ apiUrl.split('=')[1] })`) )
    }

    // Optimize Data Route using GeoServer
    optimizeWithGeoServer = () => {
        this.props.dispatch( optimizeDataWithGeoServer() )
    }

    // Optimize Railway Routing
    optimizeRailwayRouting = () => {
        this.props.dispatch( getRailwayRoute() )
    }

    // Optimize River Routing
    optimizeRiverRouting = () => {
        this.props.dispatch( getRiverRoute() )
    }

    // Optimize Bicycle Routing
    optimizeBicycleRouting = () => {
        this.props.dispatch( getBicycleRoute() )
    }

    ///////////////
    // Utilities //
    ///////////////
    _generateDatasetIdList = () => {
        const { dataSources } = this.props.dataState
        return dataSources.map(dataSource => dataSource.dataId)
    }

    _generateDatasetTableRows = () => {
        const { dataSources } = this.props.dataState
        return dataSources.map(dataSource => [ dataSource.source, dataSource.rows ])
    }

    // Handle Area Url Change on Area Data Select
    _handleAreaUrlChange = e => {
        this.setState({ apiUrl: e.target.value })
    }

    // Get Area Options
    _getAreaOptions = () => {
        return API_DATA_CONFIG.AVAILABLE_AREAS.map(a => ({
            label: a,
            value: API_DATA_CONFIG.AREA_API + a
        }))
    }

    render() {
        const { isLoading, isRupantorRunning, isApiFetching, isSnackbarOpen, error } = this.props.dataState
        const { totalDataLength, totalProcessed } = this.props.dataState.rupantorProgress
        const { totalContentLength, totalLoaded } = this.props.dataState.apiProgress
        const { datasets, clicked } = this.props.visState
        const { areaOptions, apiUrl } = this.state

        return (
            <Container disableGutters={ true } maxWidth={ false } style={ tabPanelContainerStyles }>
                <Container disableGutters={ true } maxWidth={ false } style={ headerContainerStyles }>
                    <Typography
                        variant='h6'
                        display='block'
                        gutterBottom={ true }
                        align='left'
                        style={ headerStyles }
                    >
                        { 'Datasets' }
                    </Typography>

                    { isLoading &&
                        <Box width='100%' ml={ 2 } display='flex' flexDirection='column' justifyContent='center' alignItems='center' alignSelf='flex-start' mt='4px'>
                            <Box width='100%' display='flex' justifyContent='space-between' alignItems='center'>
                                <Typography
                                    variant='caption'
                                    color='textSecondary'
                                    style={{ color: 'rgba(255, 255, 255, 0.5)', fontSize: '10px' }}
                                >
                                    { isApiFetching ? 'Fetching data' : isRupantorRunning ? 'Rupantor is processing' : '' }
                                </Typography>
                                
                                <Typography
                                    variant='caption'
                                    color='textSecondary'
                                    style={{ color: 'rgba(255, 255, 255, 0.5)', fontSize: '10px' }}
                                >
                                    {
                                        isApiFetching ?
                                        totalContentLength > 0 ?
                                        `(${ (totalLoaded / 1048576).toFixed(2) }/${ (totalContentLength / 1048576).toFixed(2) }) MB` :
                                        `(${ (totalLoaded / 1048576).toFixed(2) }/Unknown) MB` :
                                        isRupantorRunning ?
                                        `(${ totalProcessed }/${ totalDataLength })` :
                                        ``
                                    }
                                </Typography>
                            </Box>

                            <Box width='100%'>
                                {
                                    isRupantorRunning || (isApiFetching && totalContentLength > 0) ?
                                    (
                                        <StyledLinearProgress
                                            variant='determinate'
                                            value={
                                                isRupantorRunning ?
                                                totalDataLength <= 0 ? 0 : (totalProcessed / totalDataLength) * 100 :
                                                isApiFetching && totalContentLength > 0 ?
                                                (totalLoaded / totalContentLength) * 100 :
                                                0
                                            }
                                        />
                                    ) :
                                    (
                                        <StyledLinearProgress variant='indeterminate' />
                                    )
                                }
                            </Box>
                        </Box>
                    }
                </Container>

                <Box style={ fileInputBoxStyles }>
                    <input
                        id='file-upload'
                        type='file'
                        accept='.csv,.CSV,.xls,.XLS,.xlsx,.XLSX'
                        style={ fileInputStyles }
                        onChange={ this.handleFileUpload }
                    />
                    <label htmlFor='file-upload'>
                        <StyledButton
                            variant='contained'
                            component='span'
                            startIcon={ <PublishIcon /> }
                        >
                            { 'Upload' }
                        </StyledButton>
                    </label>
                </Box>

                <Typography
                    variant='body1'
                    display='block'
                    gutterBottom={ true }
                    align='center'
                    style={ orTextStyles }
                >
                    Or...
                </Typography>

                <Box style={ addUrlBoxStyles }>
                    <form onSubmit={ this.handleApiUrlSubmission } style={ urlFormStyles }>
                        {/* <StyledTextField
                            label='API URL'
                            multiline={ true }
                            rowsMax={ 4 }
                            name='apiUrl'
                            size='small'
                            type='text'
                            variant='outlined'
                            onChange={ this.handleInputOnChange }
                        />
                        <StyledButton
                            type='submit'
                            variant='contained'
                            style={{ marginLeft: '4px' }}
                        >
                            { 'Add Url' }
                        </StyledButton> */}

                        <Box width='120px'>
                            <StyledSelectMenu
                                name='apiUrl'
                                menuItems={ areaOptions }
                                label='Select Area'
                                value={ apiUrl }
                                onChange={ this._handleAreaUrlChange }
                            />
                        </Box>

                        <StyledButton
                            type='submit'
                            variant='contained'
                            style={{ marginLeft: '4px', marginBottom: '4px', height: '40px' }}
                        >
                            { 'Add Data' }
                        </StyledButton>
                    </form>
                </Box>

                { datasets !== undefined && Object.keys(datasets).length > 0 &&
                    <Box width='100%'>
                        <StyledTable
                            idList={ this._generateDatasetIdList() }
                            headers={['Source', '#Rows']}
                            rows={ this._generateDatasetTableRows() }
                            onRowDelete={ this.handleRemoveDataset }
                        />
                    </Box>
                }

                {
                    clicked &&
                    <SelectedDataInfo clicked={ clicked } />
                }

                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    open={ isSnackbarOpen }
                    autoHideDuration={ 5000 }
                    message={ error.message ? error.message : '' }
                    onClose={ this.handleSnackbarClose }
                    TransitionComponent={ Slide }
                    action={
                        <React.Fragment>
                            <IconButton size='small' aria-label='close' color='inherit' onClick={ this.handleSnackbarClose }>
                                <CloseIcon fontSize='small' />
                            </IconButton>
                        </React.Fragment>
                    }
                />

                <StyledButton
                    variant='contained'
                    size='small'
                    style={{ marginTop: '24px', marginLeft: 'auto' }}
                    onClick={ this.optimizeWithGeoServer }
                >
                    { 'On-Road Routing' }
                </StyledButton>

                <StyledButton
                    variant='contained'
                    size='small'
                    style={{ marginTop: '8px', marginLeft: 'auto' }}
                    onClick={ this.optimizeRailwayRouting }
                >
                    { 'Railway Routing' }
                </StyledButton>

                <StyledButton
                    variant='contained'
                    size='small'
                    style={{ marginTop: '8px', marginLeft: 'auto' }}
                    onClick={ this.optimizeBicycleRouting }
                >
                    { 'Bicycle Routing' }
                </StyledButton>

                <StyledButton
                    variant='contained'
                    size='small'
                    style={{ marginTop: '8px', marginLeft: 'auto' }}
                    onClick={ this.optimizeRiverRouting }
                >
                    { 'River Routing' }
                </StyledButton>
            </Container>
        )
    }
}

// JSS Styled Components
const StyledButton = withStyles({
    root: {
        background: 'rgb(45, 219, 172, 0.6)',
        color: '#fff',
        '&:hover': {
            background: 'rgb(45, 219, 172, 0.6)'
        }
    }
})(Button)

const StyledLinearProgress = withStyles({
    root: {
        borderRadius: 4,
        height: '4px'
    },
    colorPrimary: {
        backgroundColor: 'grey'
    },
    bar: {
        borderRadius: 4,
        backgroundColor: '#2ddbac'
    }
})(LinearProgress)

// const StyledTextField = withStyles({
//     root: {
//         '& .MuiFormLabel-root': {
//             color: 'rgba(255, 255, 255, 0.8)',
//             fontSize: '14px'
//         },
//         '& label.Mui-focused': {
//             color: '#fff'
//         },
//         '& .MuiOutlinedInput-root': {
//             color: '#fff',
//             fontSize: '12px',
//             fontWeight: '100',
//             '& fieldset': {
//                 borderColor: 'rgba(255, 255, 255, 0.5)'
//             },
//             '&:hover fieldset': {
//                 borderColor: 'rgba(255, 255, 255, 0.8)'
//             },
//             '&.Mui-focused fieldset': {
//                 borderColor: '#2ddbac'
//             }
//         }
//     }
// })(TextField)

// JSS Styles
const tabPanelContainerStyles = {
    margin: '0px',
    padding: '4px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center'
}

const headerContainerStyles = {
    width: '100%',
    margin: '0px',
    padding: '0px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
}

const headerStyles = {
    color: '#fff',
    textTransform: 'uppercase'
}

const fileInputBoxStyles = {
    margin: '0px',
    padding: '8px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
}

const fileInputStyles = {
    display: 'none'
}

const orTextStyles = {
    color: '#fff',
    width: '100%'
}

const addUrlBoxStyles = {
    margin: '0px',
    padding: '0px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
}

const urlFormStyles = {
    margin: 0,
    padding: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end'
}

const mapStateToProps = state => ({
    dataState: state.app.dataState,
    visState: state.keplerGl.map ? state.keplerGl.map.visState : {}
})
const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(DatasetsTabPanel)