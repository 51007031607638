// Import Third-party Packages
import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import Container from '@material-ui/core/Container'
import CollapseIconButton from '../common/CollapseIconButton'
import WhiteSpace from '../common/WhiteSpace'
import Header from './Header'
import Footer from './Footer'
import Body from './Body'

// Import CSS Styles

// Import Actions

class SidePanel extends React.PureComponent {
    state = {
        background: 'rgba(56, 64, 78, 0.8)',
        border: '2px solid rgba(56, 64, 78)'
    }

    componentDidMount() {
        const { theme } = this.props
        this._setTheme(theme)
    }

    componentDidUpdate(prevProps) {
        const { theme } = this.props

        // If Theme changes in props
        if(prevProps.theme !== theme) {
            this._setTheme(theme)
        }
    }

    // Set Theme
    _setTheme = theme => {
        let background = ''
        let border = ''

        switch(theme) {
            case 'light':
                background = 'rgba(255, 255, 255, 0.8)'
                border = '2px solid rgba(255, 255, 255)'
                break
            case 'dark':
                background = 'rgba(56, 64, 78, 0.8)'
                border = '2px solid rgba(56, 64, 78)'
                break
            default:
                background = 'rgba(56, 64, 78, 0.8)'
                border = '2px solid rgba(56, 64, 78)'
        }

        this.setState({ background, border })
    }

    render() {
        const { theme, hideSidePanel } = this.props
        const { background, border } = this.state

        return (
            <Container disableGutters={ true } fixed={ true } style={ sidePanelContainerStyles }>
                <Container disableGutters={ true } fixed={ true } style={{ ...sidePanelStyles, background, border }}>
                    <Header title='Catena' theme={ theme } />
                    <Body />
                    <Footer theme={ theme } />
                </Container>

                <WhiteSpace width='4px' height='0px' />
                <CollapseIconButton theme={ theme } onClick={ hideSidePanel }/>
            </Container>
        )
    }
}

// JSS Styles
const sidePanelContainerStyles = {
    position: 'absolute',
    zIndex: '99',
    width: '400px',
    height: '100vh',
    maxWidth: '400px',
    maxHeight: '100vh',
    padding: '8px',
    pointerEvents: 'none',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItem: 'center',
    overflow: 'hidden'
}

const sidePanelStyles = {
    width: '100%',
    height: '100%',
    maxWidth: '360px',
    maxHeight: '100%',
    borderRadius: '4px',
    pointerEvents: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center'
}

SidePanel.propTypes = {
    theme: PropTypes.oneOf([ 'light', 'dark' ]),
    hideSidePanel: PropTypes.func.isRequired
}

SidePanel.defaultProps = {
    theme: 'dark'
}

export default SidePanel