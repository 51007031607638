// Import Action Types
import * as ActionTypes from '../actions/actionTypes'

const initialState = {
    dataSources: [],
    isLoading: false,
    isLoaded: false,
    isRupantorRunning: false,
    rupantorProgress: {
        totalDataLength: 0,
        totalProcessed: 0
    },
    isApiFetching: false,
    apiProgress: {
        totalContentLength: 0,
        totalLoaded: 0
    },
    isSnackbarOpen: false,
    error: {}
}

const dataReducer = (state=initialState, action) => {
    const { dataSources } = state

    switch(action.type) {
        case ActionTypes.DATA_REQUEST:
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                error: {}
            }

        case ActionTypes.DATA_LOAD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: {}
            }

        case ActionTypes.DATA_LOAD_ERROR:
            return {
                ...state,
                isLoading: false,
                isLoaded: false,
                isSnackbarOpen: true,
                error: action.payload ? action.payload : { message: 'Error Occured!' }
            }

        case ActionTypes.RESET_DATA_ERROR:
            return {
                ...state,
                isSnackbarOpen: false,
                error: {}
            }
        
        case ActionTypes.ADD_DATA_SOURCE:
            // Check if payload dataId already exists or dataId is provided with payload
            if((!action.payload.dataId) || (dataSources.find(item => item.dataId === action.payload.dataId))) {
                return {
                    ...state,
                    error: { ...state.error, dataSourcesError: { message: 'Data source already exists.' } }
                }
            }

            return {
                ...state,
                dataSources: [ ...state.dataSources, action.payload ]
            }

        case ActionTypes.UPDATE_DATA_SOURCE:
            const dataSourceIndex = state.dataSources.findIndex(item => item.dataId === action.payload.dataId)
            if(dataSourceIndex >= 0) {
                dataSources[dataSourceIndex] = { ...dataSources[dataSourceIndex], ...action.payload }
                return {
                    ...state,
                    dataSources
                }
            }

            return state

        case ActionTypes.REMOVE_DATA_SOURCE:
            return {
                ...state,
                dataSources: dataSources.filter(item => item.dataId !== action.payload)
            }

        case ActionTypes.SET_IS_RUPANTOR_RUNNING:
            return {
                ...state,
                isRupantorRunning: action.payload
            }

        case ActionTypes.SET_RUPANTOR_TOTAL_DATA_LENGTH:
            return {
                ...state,
                rupantorProgress: { ...state.rupantorProgress, totalDataLength: action.payload }
            }

        case ActionTypes.SET_RUPANTOR_PROCESSED_LENGTH:
            return {
                ...state,
                rupantorProgress: { ...state.rupantorProgress, totalProcessed: action.payload }
            }
        
        case ActionTypes.SET_IS_API_FETCHING:
            return {
                ...state,
                isApiFetching: action.payload
            }
        
        case ActionTypes.SET_API_TOTAL_CONTENT_LENGTH:
            return {
                ...state,
                apiProgress: { ...state.apiProgress, totalContentLength: action.payload }
            }
        
        case ActionTypes.SET_API_DATA_LOADED_LENGTH:
            return {
                ...state,
                apiProgress: { ...state.apiProgress, totalLoaded: action.payload }
            }

        default:
            return state
    }
}

export default dataReducer