// Import Third-party Packages
import React from 'react'

// Import Components
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import TabsMenu from './TabsMenu'
import TabPanel from './TabPanel'
import DatasetsTabPanel from './Datasets/DatasetsTabPanel'
import LayersTabPanel from './Layers/LayersTabPanel'
import FiltersTabPanel from './Filters/FiltersTabPanel'
import LogoutButton from '../common/LogoutButton'

class Body extends React.PureComponent {
    state = {
        tabIndex: 0
    }

    // Handle Tab Panel Change
    handleTabOnChange = (event, tabIndex) => {
        this.setState({ tabIndex })
    }

    render() {
        const { tabIndex } = this.state

        return (
            <Container disableGutters={ true } maxWidth={ false } style={ bodyContainerStyles }>
                <TabsMenu tabIndex={ tabIndex } onChange={ this.handleTabOnChange } />

                <TabPanel value={ tabIndex } index={ 0 }>
                    <DatasetsTabPanel />
                </TabPanel>

                <TabPanel value={ tabIndex } index={ 1 }>
                    <LayersTabPanel />
                </TabPanel>

                <TabPanel value={ tabIndex } index={ 2 }>
                    <FiltersTabPanel />
                </TabPanel>

                <Box marginLeft='auto' marginTop='auto'>
                    <LogoutButton />
                </Box>
            </Container>
        )
    }
}

// JSS Styles
const bodyContainerStyles = {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center'
}

export default Body