import axios from 'axios'
import { dispatchFileDataToMap, dispatchApiDataToMap } from './dataActions'
import * as ActionTypes from './actionTypes'

// Global Variables
const RUPANTOR_API = 'https://rupantor.barikoi.com/bkoi/transparse'

// Process Data if `lat` `long` is unavailable but `address` is available with Rupantor.
export function processWithRupantorAndDispatch(data, addressColumnName, file=null, apiUrl=null) {
    return dispatch => {
        new Promise((resolve, reject) => {
            // Handle Rejection if No Addres Column Found
            if(!addressColumnName) {
                reject(new Error('No Address Found!'))
            }

            // Dispatch Rupantor Running Action
            dispatch( setIsRupantorRunning(true) )
    
            // Data Length
            const totalDataLength = data.length
            dispatch( setRupantorTotalDataLength(totalDataLength) )
    
            // Process each row `address`
            let totalProcessed = 0
            data.forEach(row => {
                const formData = new FormData()
                formData.append('addr', row[addressColumnName])
                axios.post(RUPANTOR_API, formData)
                    .then(rupantorRes => {
                        // Add `latitude` and `longitude` to existing data
                        row.latitude = rupantorRes.data.geocoded ? rupantorRes.data.geocoded.latitude : null
                        row.longitude = rupantorRes.data.geocoded ? rupantorRes.data.geocoded.longitude : null
                        totalProcessed++

                        // Dispatch Rupantor Progress
                        dispatch( setRupantorTotalProcessed(totalProcessed) )
    
                        // Check for data end
                        if(totalProcessed >= totalDataLength) {
                            resolve(data)
                        }
                    })
                    .catch(err => console.error(err))
            })
        })
        .then(rupantorRes => {
            // Filter out Invalid Data
            rupantorRes = rupantorRes.filter(d => d.longitude && d.latitude)

            // Dispatch Rupantor Stopped Action
            dispatch( setIsRupantorRunning(false) )

            // Dispatch Updated data to Map
            if(file) {
                dispatch( dispatchFileDataToMap(rupantorRes, file) )

            } else if(apiUrl) {
                dispatch( dispatchApiDataToMap(rupantorRes, apiUrl) )
            }
        })
        .catch(err => console.error(err))
    }
}

// Dispatch Set isRupantorRunning
function setIsRupantorRunning(value) {
    return dispatch => {
        dispatch({ type: ActionTypes.SET_IS_RUPANTOR_RUNNING, payload: value })
    }
}

// Dispatch Rupantor Total Data Length
function setRupantorTotalDataLength(totalDataLength) {
    return dispatch => {
        dispatch({ type: ActionTypes.SET_RUPANTOR_TOTAL_DATA_LENGTH, payload: totalDataLength })
    }
}

// Dispatch Rupantor Total Processed
function setRupantorTotalProcessed(totalProcessed) {
    return dispatch => {
        dispatch({ type: ActionTypes.SET_RUPANTOR_PROCESSED_LENGTH, payload: totalProcessed })
    }
}