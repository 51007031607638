import { uniq } from 'lodash'

// Generate Filter Value Dropdown List based on Current Layer Data Connected to the dataset and layer
export function generateFilterValueDropdownList(filters, filter, filterIndex, layers, layerData) {
    let uniqueValues = []

    // If 'multiSelect' filter and filterIndex is zero, then use filter.domain
    if(filter.type === 'multiSelect' && filterIndex === 0) {
        uniqueValues = filter.domain

    } else {
        // Find Layer Indexes that are connected to this filter
        const layerIndexes = []
        layers.forEach((layer, index) => {
            if(layer.config.dataId === filter.dataId[0]) {
                layerIndexes.push(index)
            }
        })

        // Extract Layer data for the connected layers
        const currentLayerData = layerData.filter((data, index) => layerIndexes.includes(index))

        // If There already exists a numeric filter, layerData doesn't update. Handle that scenario by filtering current layerData
        const precedingNumericFilters = filters.filter((filter, index) => index < filterIndex && filter.type === 'range').map(item => ({ fieldIdx: item.fieldIdx, value: item.value }))

        if(precedingNumericFilters.length > 0) {
            currentLayerData.forEach((currentData, index) => {
                currentLayerData[index].data = currentData.data.filter(item => {
                    let satisfiesConditionals = true
                    let length = precedingNumericFilters.length
                    for(let i = 0; i < length; i++) {
                        if((item.data[precedingNumericFilters[i].fieldIdx[0]] < precedingNumericFilters[i].value[0]) || (item.data[precedingNumericFilters[i].fieldIdx[0]] > precedingNumericFilters[i].value[1])) {
                            satisfiesConditionals = false
                            break
                        }
                    }
    
                    return satisfiesConditionals
                })
            })
        }

        // Find Unique Field Values
        currentLayerData.forEach(currentData => {
            const values = currentData.data.map(item => item.data[filter.fieldIdx[0]])
            uniqueValues.push(uniq(values))
        })

        // Generate Dropdown List
        uniqueValues = uniqueValues.flat()
    }

    return uniqueValues.map((value, index) => ({
        id: index,
        value,
        label: value
    }))
}

// Generate Filter Value Range based on Current Layer Data connected to the filter
export function generateFilterValueRange(filters, filter, filterIndex, layers, layerData) {
    let min = 0
    let max = 0

    // If 'range' filter and filterIndex is zero, then use filter.domain
    if(filter.type === 'range' && filterIndex === 0) {
        if(filter.domain) {
            min = filter.domain[0]
            max = filter.domain[1]
        }

    } else {
        // Find Layer Indexes that are connected to this filter
        const layerIndexes = []
        layers.forEach((layer, index) => {
            if(layer.config.dataId === filter.dataId[0]) {
                layerIndexes.push(index)
            }
        })

        // Extract Layer data for the connected layers
        const currentLayerData = layerData.filter((data, index) => layerIndexes.includes(index))

        // If There already exists a numeric filter, layerData doesn't update. Handle that scenario by filtering current layerData
        const precedingNumericFilters = filters.filter((filter, index) => index < filterIndex && filter.type === 'range').map(item => ({ fieldIdx: item.fieldIdx, value: item.value }))

        if(precedingNumericFilters.length > 0) {
            currentLayerData.forEach((currentData, index) => {
                currentLayerData[index].data = currentData.data.filter(item => {
                    let satisfiesConditionals = true
                    let length = precedingNumericFilters.length
                    for(let i = 0; i < length; i++) {
                        if((item.data[precedingNumericFilters[i].fieldIdx[0]] < precedingNumericFilters[i].value[0]) || (item.data[precedingNumericFilters[i].fieldIdx[0]] > precedingNumericFilters[i].value[1])) {
                            satisfiesConditionals = false
                            break
                        }
                    }
    
                    return satisfiesConditionals
                })
            })
        }

        // Find Min and Max Field Values
        currentLayerData.forEach(currentData => {
            const values = currentData.data.map(item => item.data[filter.fieldIdx[0]])
            min = Math.min(...values)
            max = Math.max(...values)
        })
    }

    return [ min, max ]
}