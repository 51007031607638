// Get Address column index in Header
export function getAddressColumnName(fields) {
    let columnName = null
    const length = fields.length

    for(let i = 0; i < length; i++) {
        if(fields[i].includes('addr') || fields[i].includes('Addr')) {
            columnName = fields[i]
            break
        }
    }

    return columnName
}

// Check if a CSV header string has columns representing `latitude` and `longitude`
export function includesLatLongColumns(fields) {
    let isLatPresent = false
    let isLongPresent = false
    const fieldsLength = fields.length

    for(let i = 0; i < fieldsLength; i++) {
        // Check if lat is present
        if(!isLatPresent && (fields[i].includes('lat') || fields[i].includes('Lat'))) {
            isLatPresent = true
        }

        // Check if long present
        if(!isLongPresent && (fields[i].includes('long') || fields[i].includes('lng') || fields[i].includes('Long') || fields[i].includes('Lng'))) {
            isLongPresent = true
        }

        // Check if both already found
        if(isLatPresent && isLongPresent) {
            break
        }
    }

    return isLatPresent && isLongPresent
}