// Import Third-party Packages
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { withStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import FilterAccordion from './FilterAccordion'

// Import Actions
import { addNewFilter } from '../../../store/actions/filterActions'

class FiltersTabPanel extends React.PureComponent {
    // Handle Add New Filter
    handleAddNewFilter = () => {
        this.props.dispatch( addNewFilter() )
    }

    ///////////////
    // Utilities //
    ///////////////
    // Get Dataset by Filter
    _getDatasetByFilter = filter => {
        const dataId = filter.dataId[0]
        return this.props.datasets[dataId]
    }

    render() {
        const { filters } = this.props

        return (
            <Container disableGutters={ true } maxWidth={ false } style={ tabPanelContainerStyles }>
                <Box width='100%' justifyContent='flex-start' marginBottom='8px'>
                    <Typography
                        variant='h6'
                        display='block'
                        gutterBottom={ true }
                        align='left'
                        style={ headerStyles }
                    >
                        { 'Filters' }
                    </Typography>
                </Box>

                { filters && filters.length > 0 &&
                    <Box width='100%' flexDirection='column' justifyContent='center' alignItems='flex-start' maxHeight='352px' overflow='auto'>
                        {
                            filters.map((filter, index) =>
                                <FilterAccordion
                                    key={ filter.id }
                                    filter={ filter }
                                    filterIndex={ index }
                                    dataset={ this._getDatasetByFilter(filter) }
                                />       
                            )
                        }
                    </Box>
                }

                <Box width='100%' mt='8px' display='flex' justifyContent={ filters && filters.length === 0 ? 'center' : 'flex-end' }>
                    <StyledButton
                        variant='contained'
                        size={ filters && filters.length === 0 ? 'medium' : 'small' }
                        startIcon={ <AddIcon /> }
                        onClick={ this.handleAddNewFilter }
                    >
                        { 'Add Filter' }
                    </StyledButton>
                </Box>
            </Container>
        )
    }
}

// JSS Styled Components
const StyledButton = withStyles({
    root: {
        background: 'rgb(45, 219, 172, 0.6)',
        color: '#fff',
        '&:hover': {
            background: 'rgb(45, 219, 172, 0.6)'
        }
    }
})(Button)

// JSS Styles
const tabPanelContainerStyles = {
    width: '100%',
    margin: '0px',
    padding: '4px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center'
}

const headerStyles = {
    color: '#fff',
    textTransform: 'uppercase'
}

// Prop Types
FiltersTabPanel.propTypes = {
    filters: PropTypes.array
}

FiltersTabPanel.defaultProps = {
    filters: []
}

const mapStateToProps = state => ({
    datasets: state.keplerGl.map ? state.keplerGl.map.visState.datasets : {},
    filters: state.keplerGl.map ? state.keplerGl.map.visState.filters : []
})
const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(FiltersTabPanel)