// Import Third-party Packages
import React from 'react'
import { connect } from 'react-redux'

// Import Components
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import CardMedia from '@material-ui/core/CardMedia'
import Hidden from '@material-ui/core/Hidden'
import Link from '@material-ui/core/Link'

// Import Assets
import traceImage from '../assets/trace_image.jpg'

// Import Actions & Methods
import { handleLogin, setAuthError } from '../store/actions/authActions'

class Login extends React.PureComponent {
    state = {
        email: '',
        password: '',
        errorEmail: '',
        errorPassword: ''
    }

    // Handle on Change
    handleOnChange = event => {
        this.setState({ [ event.target.name ]: event.target.value })

        // Set Error empty if exists
        const { authError } = this.props
        const { errorEmail, errorPassword } = this.state
        if(event.target.name === 'email' && errorEmail) {
            this.setState({ errorEmail: '' })
        }

        if(event.target.name === 'password' && errorPassword) {
            this.setState({ errorPassword: '' })
        }

        if(authError) {
            this.props.dispatch( setAuthError('') )
        }
    }

    // Handle On Submit
    handleOnSubmit = event => {
        event.preventDefault()

        let { email, password } = this.state

        // Validate Email & Password
        email = email.trim()
        const validateEmail = this._validateEmail(email)
        const validatePassword = this._validatePassword(password)
        if(validateEmail.success && validatePassword.success) {
            this.props.dispatch( handleLogin({ email, password }) )

        } else {
            this.setState({ errorEmail: validateEmail.message, errorPassword: validatePassword.message })
        }
    }

    ///////////////////////
    // Utility Functions //
    ///////////////////////

    // Validate email
    _validateEmail = email => {
        email = email.trim()
        const verdict = { success: false, message: '' }

        if (email) {
            if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
                verdict.success = true
                verdict.message = ''

            } else {
                verdict.success = false
                verdict.message = 'Invalid email address.'
            }

        } else {
            verdict.success = false
            verdict.message = 'Required field.'
        }

        return verdict
    }

    _validatePassword = password => {
        const verdict = { success: false, message: '' }

        if (password) {
            if (password.length < 6) {
                verdict.success = false
                verdict.message = 'Password must be atleast 6 digit!'

            } else {
                verdict.success = true
                verdict.message = ''
            }

        } else {
            verdict.success = false
            verdict.message = 'Required field.'
        }

        return verdict
    }

    render() {
        const { authError } = this.props
        const { errorEmail, errorPassword } = this.state

        return (
            <Box
                display='flex'
                flexDirection='row'
                height='100vh'
                width='100%'
                alignItems='center'
                justifyContent='space-evenly'
                overflow='hidden'
                padding='0px 16px 0px 16px'
            >
                <Hidden smDown={ true }>
                    <Box width='500px' height='500px'>
                        <CardMedia
                            image={ traceImage }
                            style={{ width: '100%', height: '100%' }}
                        />
                    </Box>
                </Hidden>

                <Box // RIGHT BOX (LOGIN PANEL)
                    display='flex'
                    flexDirection='column'
                    width='400px'
                    textAlign='left'
                >
                    <Box width='100%' mb='24px'>
                        <Typography component='h1' variant='h5' style={{ fontSize: '30px' }}>{ 'Catena' }</Typography>
                    </Box>

                    { authError &&
                        <Box width='100%'>
                            <Typography variant='caption' style={{ color: '#f44336' }}>{ authError }</Typography>
                        </Box>
                    }

                    <Box width='100%' mt='1rem'>
                        <form onSubmit={ this.handleOnSubmit }>
                            <Box marginBottom='2rem'>
                                <Typography variant='h6'>{ 'Email' }</Typography>

                                <StyledTextField
                                    error={ authError || errorEmail ? true : false }
                                    variant='outlined'
                                    margin='none'
                                    size='small'
                                    fullWidth={ true }
                                    name='email'
                                    type='text'
                                    autoComplete='email'
                                    onChange={ this.handleOnChange }
                                    helperText={ errorEmail ? errorEmail : null }
                                    placeholder='Enter your email here'
                                    inputProps={{ style: { color: '#000' } }}
                                />
                            </Box>

                            <Box marginBottom='2rem'>
                                <Typography variant='h6'>{ 'Password' }</Typography>
                                
                                <StyledTextField
                                    variant='outlined'
                                    margin='none'
                                    size='small'
                                    fullWidth={ true }
                                    name='password'
                                    type='password'
                                    autoComplete='current-password'
                                    onChange={ this.handleOnChange }
                                    error={ authError || errorPassword ? true : false }
                                    helperText={ errorPassword ? errorPassword : null }
                                    placeholder='Enter your password here'
                                    inputProps={{ style: { color: '#000' } }}
                                />
                            </Box>

                            <StyledButton
                                type='submit'
                                fullWidth={ true }
                                variant='contained'
                            >
                                { 'Log In' }
                            </StyledButton>
                        </form>

                        <Box width='100%' display='flex' justifyContent='center' alignItems='center'>
                            <Typography
                                variant='body2' display='block' align='center' style={ attributionTextStyles }
                            >
                                { 'Developed By | ' }
                            </Typography>

                            <Link href='https://barikoi.com' target='_blank' rel='noopener noreferrer'>
                                <Typography
                                    variant='body2' display='block' align='center' style={ attributionLinkStyles }
                                >
                                    { 'Barikoi Technologies Limited' }
                                </Typography>
                            </Link>
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    }
}

// JSS Styles
const attributionTextStyles = {
    color: 'rgba(0, 0, 0, 0.8)',
    fontSize: '10px'
}


const attributionLinkStyles = {
    marginLeft: '4px',
    fontSize: '11px',
    color: 'rgba(41, 194, 153, 0.8)'
}

// JSS Styled Components
const StyledTextField = withStyles({
    root: {
        '& .MuiFormLabel-root': { // initial form label color
            color: 'grey'
        },
        '& label.Mui-focused': { // focused form label color
            color: 'black'
        },
        '& .MuiOutlinedInput-root': { // initial outline color
            width: '100%',
            '& fieldset': {
                borderColor: 'rgba(41, 194, 153, 0.8)'
            },
            '&:hover fieldset': {  // hover outline color
                borderColor: 'rgba(41, 194, 153, 1)'
            },
            '&.Mui-focused fieldset': { // focused outline color
                borderColor: 'rgba(41, 194, 153, 0.8)'
            }
        }
    }
})(TextField)

const StyledButton = withStyles({
    root: {
        marginBottom: '1rem',
        boxShadow: 'none',
        backgroundColor: 'rgba(41, 194, 153, 0.8)',
        '&:hover': {
            backgroundColor: 'rgba(41, 194, 153, 1)',
            borderColor: '#0062cc',
            boxShadow: 'none'
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#0062cc',
            borderColor: '#005cbf'
        },
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        }
    },
    label: {
        paddingTop: '4px',
        color: '#FFFFFF'
    }
})(Button)

const mapStateToProps = state => ({ authError: state.app.auth.error.message })
const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(Login)