import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import { Box, TableContainer, Table, TableBody, TableRow, TableCell } from '@material-ui/core'

class DataInfo extends React.PureComponent {
    render() {
        const { header, rows } = this.props

        return (
            <Box width='100%' boxSizing='border-box'>
                <span style={{ ...HeaderStyles }}>{ header }</span>
                <StyledTableContainer>
                    <Table size='small' style={{ tableLayout: 'fixed', whiteSpace: 'nowrap' }}>
                        <TableBody>
                            { rows.map((row, index) => (
                                <TableRow key={ index } id={ index }>
                                    {
                                        row.map((cell, index) =>
                                            index === 0 ?
                                            (
                                                <StyledRowTableCell key={ index } style={{ opacity: '0.8', }} align='left'>
                                                    { cell }
                                                </StyledRowTableCell>
                                            ) :
                                            (
                                                <StyledRowTableCell key={ index } align='right'>
                                                    { cell }
                                                </StyledRowTableCell>
                                            )
                                        )
                                    }
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </StyledTableContainer>
            </Box>
        )
    }
}

// JSS Styles
const StyledTableContainer = withStyles({
    root: {
        background: 'transparent',
        width: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        maxHeight: '200px', // max height for table container
        tableLayout: 'auto',
    }
})(TableContainer)

const StyledRowTableCell = withStyles({
    root: {
        fontSize: '11px',
        color: 'white',
        borderBottom: 'none',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',
        overflow: 'hidden',
        wordWrap: 'break-word',
        padding: '4px 0px 4px 12px'
    }
})(TableCell)

const HeaderStyles = {
    width: '100%',
    display: 'inline-block',
    textAlign: 'center',
    fontSize: '16px',
    color: 'white'
}

// Prop Types
DataInfo.propTypes = {
    header: PropTypes.string,
    rows: PropTypes.array
}

DataInfo.defaultProps = {
    header: 'Table Header',
    rows: []
}

export default DataInfo