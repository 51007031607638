// Import Third-party Packages
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import Slider from '@material-ui/core/Slider'
import Input from '@material-ui/core/Input'
import StyledSelectMenu from '../../common/StyledSelectMenu'

// Import Actions
import { layerVisConfigChange, layerVisualChannelConfigChange } from 'kepler.gl/actions'

class LayerRadiusPanel extends React.PureComponent {
    state = {
        expanded: true
    }

    // Handle Toggle Expande/Collapse
    handleToggleExpandCollapse = () => {
        this.setState({ expanded: !this.state.expanded })
    }

    // Handle Layer Radius On Change
    handleLayerRadiusOnChange = (event, newRadius) => {
        const { layer, layerType } = this.props
        const radius = newRadius ? newRadius : Number(event.target.value)

        if(radius < 0 || radius > 500) {
            return
        }

        // Dispatch Layer Radius Change
        if(layerType === 'hexagon') {
            this.props.dispatch( layerVisConfigChange(layer, { worldUnitSize: radius }) )

        } else {
            this.props.dispatch( layerVisConfigChange(layer, { radius }) )
        }
    }

    // Handle Layer Radius Range On Change
    handleLayerRadiusRangeOnChange = (event, newRadiusRange) => {
        const { layer } = this.props
        const radiusRange = newRadiusRange ?
            newRadiusRange
            :
            [
                event.target.name === 'radiusMin' ? Number(event.target.value) : layer.config.visConfig.radiusRange[0],
                event.target.name === 'radiusMax' ? Number(event.target.value) : layer.config.visConfig.radiusRange[1]
            ]

        if(radiusRange < 0 || radiusRange > 500) {
            return
        }

        // Dispatch Layer Radius Change
        this.props.dispatch( layerVisConfigChange(layer, { radiusRange }) )
    }

    // Handle Radius Field By On Change
    handleRadiusByOnChange = event => {
        const { layer } = this.props
        const dataColumns = this._getDataColumns()
        const selectedField = dataColumns.find(column => column.value === event.target.value)

        // Dispatch Radius By
        const newConfig = {
            sizeField: {
                analyzerType: selectedField.analyzerType,
                format: selectedField.format,
                id: selectedField.id,
                name: selectedField.name,
                tableFieldIndex: selectedField.tableFieldIndex,
                type: selectedField.type
            }
        }

        this.props.dispatch( layerVisualChannelConfigChange(layer, newConfig, 'size') )
    }

    // Handle Hexagon Layer Coverage On Change
    handleHexLayerCoverageOnChange = (event, newCoverage) => {
        const { layer } = this.props
        const coverage = newCoverage ? newCoverage : Number(event.target.value)

        if(coverage < 0 || coverage > 500) {
            return
        }

        // Dispatch Layer Radius Change
        this.props.dispatch( layerVisConfigChange(layer, { coverage }) )
    }

    ///////////////
    // Utilities //
    ///////////////

    // Get Columns List
    _getDataColumns = () => {
        return this.props.dataFields.map(field => ({
            ...field,
            value: field.name,
            label: field.name
        }))
    }

    render() {
        const { layer, layerType } = this.props
        const { expanded } = this.state

        return (
            <Box width='100%' marginTop='8px' marginBottom='8px'>
                <StyledAccordion
                    expanded={ expanded }
                    onChange={ this.handleToggleExpandCollapse }
                >
                    <StyledAccordionSummary
                        expandIcon={ <ExpandMoreIcon fontSize='small' style={{ color: '#fff' }} /> }
                    >
                        <Typography
                            variant='caption'
                            style={{ color: 'rgba(255, 255, 255, 0.5)' }}
                        >
                            { 'Radius' }
                        </Typography>
                    </StyledAccordionSummary>

                    <StyledAccordionDetails>
                        <Box
                            width='100%'
                            display='flex'
                            flexDirection='column'
                            justifyContent='center'
                            alignItems='center'
                        >
                            <Box width='100%'>
                                { layer.config.sizeField ?
                                    (
                                        <Box width='100%' display='inline-flex' alignItems='center'>
                                            <StyledInput
                                                disableUnderline={ true }
                                                name='radiusMin'
                                                value={ layer.config.visConfig.radiusRange[0] }
                                                onChange={ this.handleLayerRadiusRangeOnChange }
                                            />
                                            <StyledSlider
                                                min={ 0 }
                                                max={ 500 }
                                                getAriaLabel={ index => 'thumb-' + index }
                                                value={ layer.config.visConfig.radiusRange }
                                                onChange={ this.handleLayerRadiusRangeOnChange }
                                            />
                                            <StyledInput
                                                disableUnderline={ true }
                                                name='radiusMax'
                                                value={ layer.config.visConfig.radiusRange[1] }
                                                onChange={ this.handleLayerRadiusRangeOnChange }
                                            />
                                        </Box>
                                    )
                                    :
                                    (
                                        <Box width='100%' display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                                            <Typography variant='caption' style={{ marginRight: 'auto', color: 'rgba(255, 255, 255, 0.5)' }}>{ 'Hexagon Radius(Km)' }</Typography>
                                            <Box width='100%' display='inline-flex' alignItems='center'>
                                                <StyledSlider
                                                    aria-label='radius-slider'
                                                    min={ 0 }
                                                    max={ layerType === 'hexagon' ? 500 : 100 }
                                                    step={ layerType === 'hexagon' ? 0.001 : 1 }
                                                    value={ layerType === 'hexagon' ? layer.config.visConfig.worldUnitSize : layer.config.visConfig.radius }
                                                    onChange={ this.handleLayerRadiusOnChange }
                                                />
                                                <StyledInput
                                                    disableUnderline={ true }
                                                    name='radius'
                                                    value={ layerType === 'hexagon' ? layer.config.visConfig.worldUnitSize : layer.config.visConfig.radius }
                                                    onChange={ this.handleLayerRadiusOnChange }
                                                />
                                            </Box>
                                        </Box>
                                    )
                                }
                            </Box>

                            { layerType === 'point' &&
                                <StyledSelectMenu
                                    label='Radius By'
                                    value={ layer.config.sizeField ? layer.config.sizeField.name : '' }
                                    menuItems={ this._getDataColumns() }
                                    onChange={ this.handleRadiusByOnChange }
                                />
                            }

                            { layerType === 'hexagon' &&
                                <Box width='100%' display='flex' flexDirection='column' justifyContent='center' alignItems='center' marginTop='24px'>
                                    <Typography variant='caption' style={{ marginRight: 'auto', color: 'rgba(255, 255, 255, 0.5)' }}>{ 'Coverage' }</Typography>
                                    <Box Box width='100%' display='inline-flex' alignItems='center'>
                                        <StyledSlider
                                            aria-label='coverage-slider'
                                            min={ 0 }
                                            max={ 1 }
                                            step={ 0.01 }
                                            value={ layer.config.visConfig.coverage }
                                            onChange={ this.handleHexLayerCoverageOnChange }
                                        />
                                        <StyledInput
                                            disableUnderline={ true }
                                            name='coverage'
                                            value={ layer.config.visConfig.coverage }
                                            onChange={ this.handleHexLayerCoverageOnChange }
                                        />
                                    </Box>
                                </Box>
                            }
                        </Box>
                    </StyledAccordionDetails>
                </StyledAccordion>
            </Box>
        )
    }
}

// JSS Styled Components (whitStyles)
const StyledAccordion = withStyles({
    root: {
        background: 'transparent',
        boxShadow: 'none'
    }
})(Accordion)

const StyledAccordionSummary = withStyles({
    root: {
        margin: '0px',
        background: 'rgba(56, 64, 78, 0.8)',
        color: '#fff',
        fontSize: '14px',
        minHeight: '32px',
        overflow: 'hidden'
    }
})(AccordionSummary)

const StyledAccordionDetails = withStyles({
    root: {
        color: 'white',
        fontSize: '12px'
    }
})(AccordionDetails)

const StyledSlider = withStyles({
    root: {
        margin: 0,
        padding: 0
    },
    rail: {
        color: 'rgba(255, 255, 255, 0.8)',
        height: '4px'
    },
    track: {
        color: 'rgba(255, 255, 255, 0.8)',
        height: '4px'
    },
    thumb: {
        backgroundColor: '#fff',
        width: '16px',
        height: '16px',
        marginTop: -6,
        marginLeft: -8
    },
    valueLabel: {
        color: '#2ddbac',
        marginLeft: '4px'
    }
})(Slider)

const StyledInput = withStyles({
    root: {
        background: 'rgba(56, 64, 78, 0.8)',
        width: '36px',
        paddingLeft: '4px',
        paddingRight: '4px',
        marginLeft: '8px',
        marginRight: '8px',
        color: 'rgba(255, 255, 255, 0.8)',
        fontSize: '10px'
    },
    input: {
        textAlign: 'center'
    }
})(Input)

// Prop Types
LayerRadiusPanel.propTypes = {
    layer: PropTypes.object.isRequired,
    layerType: PropTypes.string.isRequired,
    dataFields: PropTypes.array.isRequired
}

const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(null, mapDispatchToProps)(LayerRadiusPanel)