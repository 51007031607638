import axios from 'axios'
import { AUTH } from '../../App.config'
import * as ActionTypes from './actionTypes'

// Handle Login Action
export function handleLogin({ email, password }) {
    return dispatch => {
        // Request Login Access
        axios.post(AUTH.LOGIN_API, { email, password })
            .then(res => {
                // If Login Success
                if(res.data.success) {
                    const token = res.data.data

                    // Save to localStorage
                    localStorage.setItem('userEmail', email)
                    localStorage.setItem('token', token)

                    // Redirect to Trace App
                    window.location.replace('/')
                }
            })
            .catch(err => {
                dispatch( setAuthError('Email or Password doesn\'t match!') )
                console.error(err)
            })
    }
}

// Handle Logout
export function handleLogout() {
    return dispatch => {
        // Clear localStorage
        localStorage.clear()

        // Redirect to login
        window.location.replace('/login')
    }
}

// Set Auth Error
export function setAuthError(message) {
    return dispatch => {
        dispatch({ type: ActionTypes.SET_AUTH_ERROR, payload: { message } })
    }
}

//////////////
// Utilities //
//////////////

// Get and Decrypt auth token from local storage
export function getAuthToken() {
    const token = localStorage.getItem('token')
    return token
}

// Validate auth token
export function tokenValidation(token) {
    return axios.get(AUTH.JWT_VALIDATION_API, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    })
        .then(res => {
            if (res.status === 200) {
                return true
            }
            return false
        })
        .catch(err => {
            console.error(err)
            return false
        })
}