// Import Third-party Packages
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import Box from '@material-ui/core/Box'
import StyledSelectMenu from '../../common/StyledSelectMenu'

// Import Actions & Utils
import { setFilter } from 'kepler.gl/actions'
import { setFilterValueRange } from '../../../store/actions/filterActions'
import { generateFilterValueDropdownList, generateFilterValueRange } from '../../../utils/filterUtils'

class FilterFieldSelectMenu extends React.PureComponent {
    componentDidUpdate(prevProps) {
        const { filters, filter, filterIndex, layers, layerData } = this.props
        const { name } = this.props.filter

        // Dispatch Filter Value Dropdown List if Field Name Changes
        if((prevProps.filter.name.length !== name.length) || (prevProps.filter.name.length > 0 && name.length > 0 && prevProps.filter.name[0] !== name[0])) {
            // Dispatch Filter Value Dropdown List
            if(filter.type === 'multiSelect') {
                const dropdownList = generateFilterValueDropdownList(filters, filter, filterIndex, layers, layerData)
                this.props.dispatch( setFilterValueRange(dropdownList, filterIndex) )

            } else if(filter.type === 'range') {
                const filterValueRange = generateFilterValueRange(filters, filter, filterIndex, layers, layerData)
                this.props.dispatch( setFilterValueRange(filterValueRange, filterIndex) )
                this.props.dispatch( setFilter(filterIndex, 'value', filterValueRange) )
            }
        }
    }

    // Handle On Filter Field Select
    handleOnFilterFieldSelect = event => {
        const { filterIndex } = this.props
        this.props.dispatch( setFilter(filterIndex, 'name', event.target.value) )
    }

    ///////////////
    // Utilities //
    ///////////////
    // Get Filter Fields List
    _getFieldsList = () => {
        return this.props.dataset.fields.map((field, index) => ({
            id: index,
            value: field.name,
            label: field.name,
            fieldType: field.type,
            tableFieldIndex: field.tableFieldIndex
        }))
    }

    render() {
        const { filter } = this.props

        return (
            <Box width='100%'>
                <StyledSelectMenu
                    label={ 'Field' }
                    value={ filter.name.length > 0 ? filter.name[0] : '' }
                    menuItems={ this._getFieldsList() }
                    onChange={ this.handleOnFilterFieldSelect }
                />
            </Box>
        )
    }
}

// Prop Types
FilterFieldSelectMenu.propTypes = {
    dataset: PropTypes.object.isRequired,
    filter: PropTypes.object.isRequired,
    filterIndex: PropTypes.number.isRequired
}

const mapStateToProps = state => ({
    filters: state.keplerGl.map ? state.keplerGl.map.visState.filters : [],
    layers: state.keplerGl.map ? state.keplerGl.map.visState.layers : [],
    layerData: state.keplerGl.map ? state.keplerGl.map.visState.layerData : []
})
const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(FilterFieldSelectMenu)