// Import Third-party Packages
import React from 'react'
import PropTypes from 'prop-types'

// Import Components
import Container from '@material-ui/core/Container'
import BarikoiLogo from '../common/BarikoiLogo'
import Typography from '@material-ui/core/Typography'

class Header extends React.PureComponent {
    state = {
        barikoiLogoTheme: 'light',
        background: 'rgba(56, 64, 78, 0.8)',
        borderBottom: '1px solid rgba(255, 255, 255, 0.5)',
        appHeaderColor: '#fff'
    }

    componentDidMount() {
        const { theme } = this.props
        this._setTheme(theme)
    }

    componentDidUpdate(prevProps) {
        const { theme } = this.props

        // If theme changes in props
        if(prevProps.theme !== theme) {
            this._setTheme(theme)
        }
    }

    // Set Theme based on Props
    _setTheme = theme => {
        let barikoiLogoTheme = ''
        let background = ''
        let borderBottom = ''
        let appHeaderColor = ''

        switch(theme) {
            case 'light':
                barikoiLogoTheme = 'dark'
                background = 'rgba(255, 255, 255, 0.8)'
                borderBottom = '1px solid rgba(56, 64, 78, 0.5)'
                appHeaderColor = 'rgba(56, 64, 78)'
                break
            case 'dark':
                barikoiLogoTheme = 'light'
                background = 'rgba(56, 64, 78, 0.8)'
                borderBottom = '1px solid rgba(255, 255, 255, 0.5)'
                appHeaderColor = '#fff'
                break
            default:
                barikoiLogoTheme = 'light'
                background = 'rgba(56, 64, 78, 0.8)'
                borderBottom = '1px solid rgba(255, 255, 255, 0.5)'
                appHeaderColor = '#fff'
        }

        this.setState({ barikoiLogoTheme, background, borderBottom, appHeaderColor })
    }

    render() {
        const { title } = this.props
        const { barikoiLogoTheme, background, borderBottom, appHeaderColor } = this.state

        return (
            <Container disableGutters={ true } style={{ ...headerContainerStyles, background, borderBottom }}>
                <BarikoiLogo size='small' theme={ barikoiLogoTheme } />
                <Typography variant='h6' style={{ ...appHeaderStyles, color: appHeaderColor }}>{ title }</Typography>
            </Container>
        )
    }
}

// JSS Styles
const headerContainerStyles = {
    width: '100%',
    margin: '0px',
    padding: '8px',
    maxHeight: '72px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
}

const appHeaderStyles = {
    margin: '0px',
    padding: '4px',
    display: 'inline-block',
    fontFamily: 'Rubik, sans-serif',
    fontWeight: '500'
}

// Prop Types
Header.propTypes = {
    theme: PropTypes.oneOf([ 'light', 'dark' ]),
    title: PropTypes.string.isRequired
}

Header.defaultProps = {
    theme: 'dark',
    title: 'App'
}

export default Header