import { layerVisConfigChange } from 'kepler.gl/actions'
import * as ActionTypes from './actionTypes'

// Set Layer Fill Color Picker Visibility
export function setLayerFillColorPickerVisibility(visibility) {
    return dispatch => {
        dispatch({ type: ActionTypes.SET_IS_FILL_COLOR_PICKER_OPEN, payload: visibility })
    }
}

// Set Point Layer Radius based on Zoom Level
export function setLayerSizeWithZoomLevel(zoom) {
    return (dispatch, getState) => {
        // Dispatch Radius Change for each point layer
        const iconRadius = getIconRadiusWithZoom(zoom)
        const lineThickness = getLineThicknessWithZoom(zoom)
        const { layers } = getState().keplerGl.map.visState
        layers.forEach(l => {
            if(getLayerType(l) === 'point') {
                dispatch( layerVisConfigChange(l, { radius: lineThickness * 15 }) )
                
            } else if(getLayerType(l) === 'icon') {
                dispatch( layerVisConfigChange(l, { radius: iconRadius }) )

            } else if(getLayerType(l) === 'geojson') {
                dispatch( layerVisConfigChange(l, { thickness: lineThickness }) )
            }
        })
    }
}

///////////////
// Utilities //
///////////////

// Get Layer Type
export function getLayerType(curLayer) {
    // If Layer exists
    if(curLayer) {
        if(curLayer.visConfigSettings.radius && curLayer.visConfigSettings.filled && !curLayer.visConfigSettings.wireframe) {
            // Point Type
            return 'point'

        } else if(curLayer.getIconAccessor && curLayer.visConfigSettings.radius && !curLayer.visConfigSettings.filled && !curLayer.visConfigSettings.wireframe) {
            // Icon Type
            return 'icon'

        } else if(curLayer.config.columns.geojson && curLayer.visConfigSettings.radius && curLayer.visConfigSettings.filled && curLayer.visConfigSettings.wireframe) {
            // Polygon Type
            return 'geojson'

        } else if(curLayer.visConfigSettings.colorAggregation && !curLayer.visConfigSettings.resolution) {
            // Grid Type
            return 'grid'

        } else if(curLayer.visConfigSettings.colorAggregation && curLayer.visConfigSettings.resolution) {
            // Hexbin Type
            return 'hexagon'

        } else if(curLayer.computeHeatmapConfiguration) {
            // Heatmap Type
            return 'heatmap'

        } else if(curLayer.visConfigSettings.clusterRadius) {
            // Cluster Type
            return 'cluster'

        } else if(curLayer.dataToFeature && curLayer.dataToTimeStamp && curLayer.config.visConfig.trailLength) {
            // Trip Type
            return 'trip'
        }
    }

    return ''
}

// Get Icon Radius with Zoom
function getIconRadiusWithZoom(zoom) {
    let radius = 60.0

    if(zoom >= 13 && zoom < 14) {
        radius = 52.0

    } else if(zoom >= 14 && zoom < 15) {
        radius = 45.0

    } else if(zoom >= 15 && zoom < 16) {
        radius = 25.0

    } else if(zoom >= 16 && zoom < 17) {
        radius = 15.0

    } else if(zoom >= 17 && zoom < 18) {
        radius = 8.0

    } else if(zoom >= 18 && zoom < 19) {
        radius = 4.0

    } else if(zoom >= 19 && zoom < 20) {
        radius = 2.5

    } else if(zoom >= 20 && zoom < 21) {
        radius = 1.5

    } else if(zoom >= 21 && zoom < 22) {
        radius = 0.7

    } else if(zoom >= 22 && zoom < 23) {
        radius = 0.4

    } else if(zoom >= 23 && zoom < 24) {
        radius = 0.2

    } else if(zoom >= 24) {
        radius = 0.1
    }

    return radius
}

// Get Poly Line Thickness With Zoom
function getLineThicknessWithZoom(zoom) {
    let thickness = 2

    if(zoom > 15 && zoom <= 16) {
        thickness = 1

    } else if(zoom > 16 && zoom <= 17) {
        thickness = 0.5

    } else if(zoom > 17 && zoom <= 18) {
        thickness = 0.4

    } else if(zoom > 18 && zoom <= 19) {
        thickness = 0.3

    } else if(zoom > 19 && zoom <= 20) {
        thickness = 0.2

    } else if(zoom > 20) {
        thickness = 0.1
    }

    return thickness
}