import React from 'react'

// Import Components
import Box from '@material-ui/core/Box'
import KeplerGlMap from '../components/KeplerGl/KeplerGlMap'

class CatenaApp extends React.PureComponent {
    render() {
        return (
            <Box { ...appContainerStyles }>
                <KeplerGlMap />
            </Box>
        )
    }
}

// JSS Styles
const appContainerStyles = {
    margin: 0,
    padding: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden'
}

export default CatenaApp