import axios from 'axios'
import { GEO_SERVER } from '../../App.config'
import { processGeojson, processRowObject } from 'kepler.gl/processors'
import { addDataToMap, layerConfigChange, layerVisConfigChange, removeDataset, layerTextLabelChange, layerVisualChannelConfigChange } from 'kepler.gl/actions'
import { getLayerType, setLayerSizeWithZoomLevel } from './layerActions'
import { setDataRequest, setDataLoadSuccess } from './dataActions'

// Optimize Data with GeoServer API
export function optimizeDataWithGeoServer() {
    return (dispatch, getState) => {
        // Get Dataset
        const { datasets } = getState().keplerGl.map.visState

        // If Optimized Route Data and waypoints data already exists, remove it
        dispatch( removeDataset(GEO_SERVER.ROUTE_DATA_ID) )
        dispatch( removeDataset(GEO_SERVER.WAYPOINTS_DATA_ID) )

        // Send Data Request
        dispatch( setDataRequest() )

        if(Object.keys(datasets).length > 0) {
            const dataset = datasets[ Object.keys(datasets)[0] ]
            const latIndex = dataset.fields.findIndex(f => f.name === 'latitude')
            const longIndex = dataset.fields.findIndex(f => f.name === 'longitude')
            const numOfData = dataset.allData.length

            if(latIndex >=0 && longIndex >= 0 && numOfData > 1) {
                const apiParams = {
                    json: {
                        locations: dataset.allData.filter(i => i[ longIndex ] && i[ latIndex ]).map(d => ({
                            lon: d[ longIndex ],
                            lat: d[ latIndex ]
                        })),
                        costing: 'auto',
                        directions_options: {
                            units: 'miles'
                        }
                    }
                }

                axios.get(GEO_SERVER.ROUTE_OPTIMIZATION_API, { params: apiParams })
                    .then(res => {
                        // Generate LineString GeoJSON object
                        const routeGeoJson = convertToGeoJson(res.data)

                        // Generate Updated Waypoints Data from response
                        const waypoints = generateWaypointsData(res.data)

                        // Dispatch Route Data to Map
                        dispatch( dispatchRouteToMap(routeGeoJson, waypoints) )
                    })
                    .then(() => {
                        // Data Fetch Done
                        dispatch( setDataLoadSuccess() )
                    })
                    .catch(err => console.error(err))
            }
        }
    }
}

// Dispatch Route Data to Map
function dispatchRouteToMap(routeGeoJson, waypoints) {
    return (dispatch, getState) => {
        // Build Route Dataset
        const routeDataInfo = { id: GEO_SERVER.ROUTE_DATA_ID, label: GEO_SERVER.ROUTE_DATA_LABEL }
        const routeData = processGeojson(routeGeoJson)
        const routeDataset = { info: routeDataInfo, data: routeData }

        // Build Waypoints Dataset
        const waypointsDataInfo = { id: GEO_SERVER.WAYPOINTS_DATA_ID, label: GEO_SERVER.WAYPOINTS_DATA_LABEL }
        const waypointsData = processRowObject(waypoints)
        const waypointsDataset = { info: waypointsDataInfo, data: waypointsData }

        // Options & Configs
        const options = { readOnly: true, centerMap: true }

        dispatch( addDataToMap({ datasets: [ waypointsDataset, routeDataset ], options }) )

        // Set Layer Colors
        const { layers } = getState().keplerGl.map.visState
        layers.forEach(l => {
            // Route Layer
            if(l.config.dataId === GEO_SERVER.ROUTE_DATA_ID && getLayerType(l) === 'geojson') {
                dispatch( layerVisConfigChange(l, { strokeColor: [ 255, 255, 0 ], thickness: 2 }) )

                // Set Line Width with Zoom
                const { zoom } = getState().keplerGl.map.mapState
                dispatch( setLayerSizeWithZoomLevel(zoom) )

            } else if(l.config.dataId === GEO_SERVER.WAYPOINTS_DATA_ID && getLayerType(l) === 'point') {
                // Waypoints Layer
                dispatch( layerConfigChange(l, { color: [ 18, 147, 154 ] }) )

                ////////////////////////////////////////
                // Set Waypoints Text Label to `name` //
                ////////////////////////////////////////
                const _waypointsDataset = getState().keplerGl.map.visState.datasets[ waypointsDataInfo.id ]
                const nameField = _waypointsDataset.fields.find(el => el.name === 'name')
                if(nameField) {
                    const textField = {
                        analyzerType: nameField.analyzerType,
                        format: nameField.format,
                        id: nameField.id,
                        name: nameField.name,
                        tableFieldIndex: nameField.tableFieldIndex,
                        type: nameField.type
                    }
                    
                    dispatch( layerTextLabelChange(l, 0, 'field', textField) )
                }

                /////////////////////////////////////////////////////
                // Color By Point Type. i.e start/end/intermediate //
                /////////////////////////////////////////////////////
                const colorRange = {
                    category: 'Barikoi',
                    colors: [ '#c90000', '#12939A', '#00FF00' ],
                    name: 'Route Waypoints',
                    type: 'quantile'
                }

                dispatch( layerVisConfigChange(l, { colorRange }) )

                // Set Color Field by to distinguish waypoint types
                const typeField = _waypointsDataset.fields.find(el => el.name === 'type')
                const newColorConfig = {
                    colorField: {
                        analyzerType: typeField.analyzerType,
                        format: typeField.format,
                        id: typeField.id,
                        name: typeField.name,
                        tableFieldIndex: typeField.tableFieldIndex,
                        type: typeField.type
                    }
                }
    
                // Dispatch Color Field By
                dispatch( layerVisualChannelConfigChange(l, newColorConfig, 'color') )

                //////////////////////////////
                // Set Line Width with Zoom //
                //////////////////////////////
                const { zoom } = getState().keplerGl.map.mapState
                dispatch( setLayerSizeWithZoomLevel(zoom) )
            }
        })
    }
}

// Generate Waypoints Data from optimized route response.
function generateWaypointsData(vhOptimizedRoute) {
    const len = vhOptimizedRoute.trip.locations.length
    const waypoints = vhOptimizedRoute.trip.locations.map((l, i) => ({
        longitude: l.lon,
        latitude: l.lat,
        name: i === 0 ?
            'Start' :
            i === len-1 ?
            'End' :
            `Int-${i}`,
        type: i === 0 ?
            'start' :
            i === len-1 ?
            'end' :
            'intermediate'
    }))

    return waypoints
}

// Decode Valhalla encoded shapes to LineStrings
// This is adapted from the implementation in Project-OSRM
// https://github.com/DennisOSRM/Project-OSRM-Web/blob/master/WebContent/routing/OSRM.RoutingGeometry.js
function decodeShape(str, precision) {
    let index = 0
    let lat = 0
    let lng = 0
    let coordinates = []
    let shift = 0
    let result = 0
    let byte = null
    let latitude_change
    let longitude_change
    let factor = Math.pow(10, precision || 6)

    // Coordinates have variable length when encoded, so just keep
    // track of whether we've hit the end of the string. In each
    // loop iteration, a single coordinate is decoded.
    while (index < str.length) {
        // Reset shift, result, and byte
        byte = null
        shift = 0
        result = 0

        do {
            byte = str.charCodeAt(index++) - 63
            result |= (byte & 0x1f) << shift
            shift += 5
        } while (byte >= 0x20)

        latitude_change = ((result & 1) ? ~(result >> 1) : (result >> 1))

        shift = result = 0

        do {
            byte = str.charCodeAt(index++) - 63
            result |= (byte & 0x1f) << shift
            shift += 5
        } while (byte >= 0x20)

        longitude_change = ((result & 1) ? ~(result >> 1) : (result >> 1))

        lat += latitude_change
        lng += longitude_change

        coordinates.push([lng / factor, lat / factor])
    }

    return coordinates
}

// Convert Valhalla Optimized Route Response to a GeoJSON Object
function convertToGeoJson(vhOptimizedRoute) {
    const polyLineGeoJson = {
        type: 'FeatureCollection',
        features: [
            {
                type: 'Feature',
                properties: {},
                geometry: {
                    type: 'LineString',
                    coordinates: vhOptimizedRoute.trip.legs.map(l => decodeShape(l.shape)).flat()
                }
            }
        ]
    }

    return polyLineGeoJson
}

/////////////////////
// Railway Routing //
/////////////////////
export function getRailwayRoute() {
    return (dispatch, getState) => {
        // Get Dataset
        const { datasets } = getState().keplerGl.map.visState

        // If Optimized Route Data and waypoints data already exists, remove it
        dispatch( removeDataset(GEO_SERVER.ROUTE_DATA_ID) )
        dispatch( removeDataset(GEO_SERVER.WAYPOINTS_DATA_ID) )

        // Send Data Request
        dispatch( setDataRequest() )

        if(Object.keys(datasets).length > 0) {
            const dataset = datasets[ Object.keys(datasets)[0] ]
            const latIndex = dataset.fields.findIndex(f => f.name === 'latitude')
            const longIndex = dataset.fields.findIndex(f => f.name === 'longitude')
            const numOfData = dataset.allData.length

            if(latIndex >=0 && longIndex >= 0 && numOfData > 1) {
                const source = `${dataset.allData[0][longIndex]},${dataset.allData[0][latIndex]}`
                const destination = `${dataset.allData[numOfData-1][longIndex]},${dataset.allData[numOfData-1][latIndex]}`
                const apiUrl = `${GEO_SERVER.RAILWAY_ROUTE_API}/${source};${destination}`

                axios.get(apiUrl, { params: { steps: true, geometries: 'geojson' } })
                    .then(res => {
                        // Generate LineString GeoJSON object
                        const routeGeoJson = generateRailwayRouteGeoJson(res.data)

                        // Generate Updated Waypoints Data from response
                        const waypoints = generateRailwayWaypoints(res.data)

                        // // Dispatch Route Data to Map
                        dispatch( dispatchRouteToMap(routeGeoJson, waypoints) )
                    })
                    .then(() => {
                        // Data Fetch Done
                        dispatch( setDataLoadSuccess() )
                    })
                    .catch(err => console.error(err))
            }
        }
    }
}

// Generate Railway Route GeoJson
function generateRailwayRouteGeoJson(data) {
    let coordinates = []
    data.routes[0].legs.forEach(l => {
        l.steps.forEach(s => {
            coordinates = coordinates.concat(s.geometry.coordinates)
        })
    })

    const polyLineGeoJson = {
        type: 'FeatureCollection',
        features: [
            {
                type: 'Feature',
                properties: {},
                geometry: {
                    type: 'LineString',
                    coordinates
                }
            }
        ]
    }

    return polyLineGeoJson
}

// Generate Railway Waypoints
function generateRailwayWaypoints(data) {
    const len = data.waypoints.length
    const waypoints = data.waypoints.map((w, i) => ({
        longitude: w.location[0],
        latitude: w.location[1],
        name: i === 0 ?
            'Start' :
            i === len-1 ?
            'End' :
            `Int-${i}`,
        type: i === 0 ?
            'start' :
            i === len-1 ?
            'end' :
            'intermediate'
    }))

    return waypoints
}

///////////////////
// River Routing //
///////////////////
export function getRiverRoute() {
    return (dispatch, getState) => {
        // Get Dataset
        const { datasets } = getState().keplerGl.map.visState

        // If Optimized Route Data and waypoints data already exists, remove it
        dispatch( removeDataset(GEO_SERVER.ROUTE_DATA_ID) )
        dispatch( removeDataset(GEO_SERVER.WAYPOINTS_DATA_ID) )

        // Send Data Request
        dispatch( setDataRequest() )

        if(Object.keys(datasets).length > 0) {
            const dataset = datasets[ Object.keys(datasets)[0] ]
            const latIndex = dataset.fields.findIndex(f => f.name === 'latitude')
            const longIndex = dataset.fields.findIndex(f => f.name === 'longitude')
            const numOfData = dataset.allData.length

            if(latIndex >=0 && longIndex >= 0 && numOfData > 1) {
                const source = `${dataset.allData[0][longIndex]},${dataset.allData[0][latIndex]}`
                const destination = `${dataset.allData[numOfData-1][longIndex]},${dataset.allData[numOfData-1][latIndex]}`
                const apiUrl = `${GEO_SERVER.RIVER_ROUTE_API}/${source};${destination}`

                axios.get(apiUrl, { params: { steps: true, geometries: 'geojson' } })
                    .then(res => {
                        // Generate LineString GeoJSON object
                        const routeGeoJson = generateRiverRouteGeoJson(res.data)

                        // Generate Updated Waypoints Data from response
                        const waypoints = generateRiverWaypoints(res.data)

                        // // Dispatch Route Data to Map
                        dispatch( dispatchRouteToMap(routeGeoJson, waypoints) )
                    })
                    .then(() => {
                        // Data Fetch Done
                        dispatch( setDataLoadSuccess() )
                    })
                    .catch(err => console.error(err))
            }
        }
    }
}

// Generate River Route GeoJson
function generateRiverRouteGeoJson(data) {
    return generateRailwayRouteGeoJson(data)
}

// Generate River Waypoints
function generateRiverWaypoints(data) {
    return generateRailwayWaypoints(data)
}

/////////////////////
// Bicycle Routing //
/////////////////////
export function getBicycleRoute() {
    return (dispatch, getState) => {
        // Get Dataset
        const { datasets } = getState().keplerGl.map.visState

        // If Optimized Route Data and waypoints data already exists, remove it
        dispatch( removeDataset(GEO_SERVER.ROUTE_DATA_ID) )
        dispatch( removeDataset(GEO_SERVER.WAYPOINTS_DATA_ID) )

        // Send Data Request
        dispatch( setDataRequest() )

        if(Object.keys(datasets).length > 0) {
            const dataset = datasets[ Object.keys(datasets)[0] ]
            const latIndex = dataset.fields.findIndex(f => f.name === 'latitude')
            const longIndex = dataset.fields.findIndex(f => f.name === 'longitude')
            const numOfData = dataset.allData.length

            if(latIndex >=0 && longIndex >= 0 && numOfData > 1) {
                const apiParams = {
                    json: {
                        locations: dataset.allData.filter(i => i[ longIndex ] && i[ latIndex ]).map(d => ({
                            lon: d[ longIndex ],
                            lat: d[ latIndex ]
                        })),
                        costing: 'bicycle',
                        directions_options: {
                            units: 'miles'
                        }
                    }
                }

                axios.get(GEO_SERVER.ROUTE_OPTIMIZATION_API, { params: apiParams })
                    .then(res => {
                        // Generate LineString GeoJSON object
                        const routeGeoJson = convertToGeoJson(res.data)

                        // Generate Updated Waypoints Data from response
                        const waypoints = generateWaypointsData(res.data)

                        // Dispatch Route Data to Map
                        dispatch( dispatchRouteToMap(routeGeoJson, waypoints) )
                    })
                    .then(() => {
                        // Data Fetch Done
                        dispatch( setDataLoadSuccess() )
                    })
                    .catch(err => console.error(err))
            }
        }
    }
}