import * as ActionTypes from './actionTypes'
import { addFilter } from 'kepler.gl/actions'

// Add Filter Action with default to the First Dataset
export function addNewFilter() {
    return (dispatch, getState) => {
        // Get Default Dataset
        const { datasets } = getState().keplerGl.map.visState
        const datasetIdList = Object.keys(datasets)
        const defaultDatasetId = datasetIdList.length > 0 ? datasetIdList[0] : null

        // If No Dataset Found
        if(!defaultDatasetId) {
            return
        }

        // Add New Filter
        dispatch( addFilter(defaultDatasetId) )
    }
}

// Set Filter Value Range
export function setFilterValueRange(range, filterIndex) {
    return dispatch => {
        dispatch({ type: ActionTypes.SET_FILTER_VALUE_RANGE, payload: { range, filterIndex } })
    }
}

// Remove Filter Value Range
export function removeFilterValueRange(filterIndex) {
    return dispatch => {
        dispatch({ type: ActionTypes.REMOVE_FILTER_VALUE_RANGE, payload: { filterIndex } })
    }
}