// Import Third-party Packages
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import FilterFieldSelectMenu from './FilterFieldSelectMenu'
import FilterValueSelectMenu from './FilterValueSelectMenu'
import Slider from '@material-ui/core/Slider'
import Input from '@material-ui/core/Input'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'

// Import Actions
import { setFilter, removeFilter } from 'kepler.gl/actions'
import { removeFilterValueRange } from '../../../store/actions/filterActions'

class FilterAccordion extends React.PureComponent {
    // Handle Filter Value Range On Change
    handleFilterRangeOnChange = (event, newFilterRange) => {
        const { filter, filterIndex } = this.props
        const filterRange = newFilterRange ?
            newFilterRange
            :
            [
                event.target.name === 'filterRangeMin' ? Number(event.target.value) : filter.value[0],
                event.target.name === 'filterRangeMax' ? Number(event.target.value) : filter.value[1]
            ]

        // Dispatch Layer Radius Change
        this.props.dispatch( setFilter(filterIndex, 'value', filterRange, 0) )
    }

    // Handle Filter Delete
    handleFilterDelete = event => {
        event.stopPropagation()
        
        // Dispatch Remove Filter
        const { filterIndex } = this.props
        this.props.dispatch( removeFilter(filterIndex) )

        // Clean Filter Value Range
        this.props.dispatch( removeFilterValueRange(filterIndex) )
    }

    render() {
        const { dataset, filter, filterIndex } = this.props
        const { filtersValueRanges } = this.props.filterState

        return (
            <Box width='100%' marginBottom='8px'>
                <StyledAccordion>
                    <StyledAccordionSummary expandIcon={ <ExpandMoreIcon style={{ color: '#fff' }} /> }>
                        <Box width='100%' flexDirection='column'>
                            <Typography variant='body2'>{ dataset.label }</Typography>
                            <Typography
                                variant='caption'
                                style={{ color: 'rgba(255, 255, 255, 0.5)' }}
                            >
                                { dataset.label }
                            </Typography>
                        </Box>
                        <Box display='flex' alignItems='center'>
                            <IconButton
                                size='small'
                                onFocus={ event => event.stopPropagation() }
                                onClick={ this.handleFilterDelete }
                            >
                                <DeleteIcon fontSize='small' style={{ color: '#fff', opacity: '0.6' }} />
                            </IconButton>
                        </Box>
                    </StyledAccordionSummary>
                    <StyledAccordionDetails>
                        <Box width='100%' flexDirection='column'>
                            <Box width='100%'>
                                <FilterFieldSelectMenu
                                    dataset={ dataset }
                                    filter={ filter }
                                    filterIndex={ filterIndex }
                                />
                            </Box>

                            { filter.type === 'multiSelect' &&
                                <Box width='100%' marginTop='4px'>
                                    <FilterValueSelectMenu
                                        filter={ filter }
                                        filterIndex={ filterIndex }
                                    />
                                </Box>
                            }

                            { filter.type === 'range' &&
                                <Box width='100%' display='inline-flex' alignItems='center'>
                                    <StyledInput
                                        disableUnderline={ true }
                                        name='filterRangeMin'
                                        value={ filter.value.length > 0 ? filter.value[0] : 0 }
                                        onChange={ this.handleFilterRangeOnChange }
                                    />
                                    <StyledSlider
                                        getAriaLabel={ index => 'thumb-' + index }
                                        min={
                                            filtersValueRanges.length > filterIndex ? filtersValueRanges[filterIndex][0] : 0
                                        }
                                        max={
                                            filtersValueRanges.length > filterIndex ? filtersValueRanges[filterIndex][1] : 100
                                        }
                                        step={ 0.01 }
                                        value={ filter.value }
                                        onChange={ this.handleFilterRangeOnChange }
                                    />
                                    <StyledInput
                                        disableUnderline={ true }
                                        name='filterRangeMax'
                                        value={ filter.value.length > 1 ? filter.value[1] : 0 }
                                        onChange={ this.handleFilterRangeOnChange }
                                    />
                                </Box>
                            }
                        </Box>
                    </StyledAccordionDetails>
                </StyledAccordion>
            </Box>
        )
    }
}

// JSS Styled Components (whitStyles)
const StyledAccordion = withStyles({
    root: {
        background: 'transparent',
        boxShadow: 'none',
        border: '1px solid rgba(255, 255, 255, 0.2)'
    }
})(Accordion)

const StyledAccordionSummary = withStyles({
    root: {
        margin: '0px',
        background: 'rgba(56, 64, 78, 0.8)',
        color: '#fff',
        fontSize: '14px',
        maxHeight: '32px',
        overflow: 'hidden'
    }
})(AccordionSummary)

const StyledAccordionDetails = withStyles({
    root: {
        color: 'white',
        fontSize: '12px'
    }
})(AccordionDetails)

const StyledSlider = withStyles({
    rail: {
        color: 'rgba(255, 255, 255, 0.8)',
        height: '4px'
    },
    track: {
        color: 'rgba(255, 255, 255, 0.8)',
        height: '4px'
    },
    thumb: {
        backgroundColor: '#fff',
        width: '16px',
        height: '16px',
        marginTop: -6,
        marginLeft: -8
    },
    valueLabel: {
        color: '#2ddbac',
        marginLeft: '4px'
    }
})(Slider)

const StyledInput = withStyles({
    root: {
        background: 'rgba(56, 64, 78, 0.8)',
        width: '48px',
        paddingLeft: '4px',
        paddingRight: '4px',
        marginLeft: '8px',
        marginRight: '8px',
        color: 'rgba(255, 255, 255, 0.8)',
        fontSize: '10px'
    },
    input: {
        textAlign: 'center'
    }
})(Input)

// Prop Types
FilterAccordion.propTypes = {
    filter: PropTypes.object.isRequired,
    filterIndex: PropTypes.number.isRequired,
    dataset: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    filterState: state.app.filterState
})
const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(FilterAccordion)