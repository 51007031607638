// Import Third-party Packages
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Import Components
import { withStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import LayerAccordion from './LayerAccordion'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'

// Import Actions
import { addLayer } from 'kepler.gl/actions'

class LayersTabPanel extends React.PureComponent {
    // Handle Add New Layer on Add Layer Clicked
    handleAddNewLayer = () => {
        const layersLength = this.props.layers.length
        this.props.dispatch( addLayer({ label: `New Layer ${ layersLength+1 }` }) )
    }

    ///////////////
    // Utilities //
    ///////////////
    _getLayerType = curLayer => {
        // If Layer exists
        if(curLayer) {
            if(curLayer.visConfigSettings.radius && curLayer.visConfigSettings.filled && !curLayer.visConfigSettings.wireframe) {
                // Point Type
                return 'point'

            } else if(curLayer.visConfigSettings.radius && curLayer.visConfigSettings.filled && curLayer.visConfigSettings.wireframe) {
                // Polygon Type
                return 'geojson'

            } else if(curLayer.visConfigSettings.colorAggregation && !curLayer.visConfigSettings.resolution) {
                // Grid Type
                return 'grid'

            } else if(curLayer.visConfigSettings.colorAggregation && curLayer.visConfigSettings.resolution) {
                // Hexbin Type
                return 'hexagon'

            } else if(curLayer.computeHeatmapConfiguration) {
                // Heatmap Type
                return 'heatmap'

            } else if(curLayer.visConfigSettings.clusterRadius) {
                // Cluster Type
                return 'cluster'
            }
        }

        return ''
    }

    // Get Dataset by Filter
    _getDataFieldsByLayer = layer => {
        const dataId = layer.config.dataId
        return this.props.datasets[dataId].fields
    }

    render() {
        const { layers } = this.props

        return (
            <Container disableGutters={ true } maxWidth={ false } style={ tabPanelContainerStyles }>
                <Box width='100%' justifyContent='flex-start' marginBottom='8px'>
                    <Typography
                        variant='h6'
                        display='block'
                        gutterBottom={ true }
                        align='left'
                        style={ headerStyles }
                    >
                        { 'Layers' }
                    </Typography>
                </Box>

                { layers && layers.length > 0 &&
                    <Box width='100%' flexDirection='column' justifyContent='center' alignItems='flex-start' maxHeight='352px' overflow='auto'>
                        {
                            layers.map((layer, index) =>
                                <LayerAccordion
                                    key={ Math.random(100) + index }
                                    layer={ layer }
                                    layerIndex={ index }
                                    layerType={ this._getLayerType(layer) }
                                    dataFields={ this._getDataFieldsByLayer(layer) }
                                />       
                            )
                        }
                    </Box>
                }

                <Box width='100%' mt='8px' display='flex' justifyContent={ layers && layers.length === 0 ? 'center' : 'flex-end' }>
                    <StyledButton
                        variant='contained'
                        size={ layers && layers.length === 0 ? 'medium' : 'small' }
                        startIcon={ <AddIcon /> }
                        onClick={ this.handleAddNewLayer }
                    >
                        { 'Add Layer' }
                    </StyledButton>
                </Box>
            </Container>
        )
    }
}

// JSS Styled Components
const StyledButton = withStyles({
    root: {
        background: 'rgb(45, 219, 172, 0.6)',
        color: '#fff',
        '&:hover': {
            background: 'rgb(45, 219, 172, 0.6)'
        }
    }
})(Button)

// JSS Styles
const tabPanelContainerStyles = {
    width: '100%',
    margin: '0px',
    padding: '4px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center'
}

const headerStyles = {
    color: '#fff',
    textTransform: 'uppercase'
}

// Prop Types
LayersTabPanel.propTypes = {
    layers: PropTypes.array
}

LayersTabPanel.defaultProps = {
    layers: []
}

const mapStateToProps = state => ({
    datasets: state.keplerGl.map ? state.keplerGl.map.visState.datasets : {},
    layers: state.keplerGl.map ? state.keplerGl.map.visState.layers : []
})
const mapDispatchToProps = dispatch => ({ dispatch })

export default connect(mapStateToProps, mapDispatchToProps)(LayersTabPanel)